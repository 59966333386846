<template>
	<div v-if="isRegularSelected" class="section">
		<h2>
			Select the days we can clean for you.
			<br>
			Choose multiple days for faster booking
		</h2>
		<ul class="grid grid-cols-2 md:grid-cols-3 gap-4">
			<li v-for="day in days" :key="day"
				class="w-full border border-gray-200 dark:border-gray-400 rounded-lg bg-white">
				<div class="flex items-center pl-3">
					<input
						:id="`preferred-days-${day.toLowerCase().replace(' ', '-')}`"
						v-model="booking.preferred_days"
						type="checkbox"
						:value="day.substring(0, 3)"
						class="w-4 h-4 form-checkbox">
					<label :for="`preferred-days-${day.toLowerCase().replace(' ', '-')}`" class="w-full py-3 ml-2 text-sm font-medium text-gray-900">
						<template v-if="day === 'Saturday'">
							Saturday*
						</template>
						<template v-else>
							{{ day }}
						</template>
					</label>
				</div>
			</li>
		</ul>
		<div class="bg-white sm:bg-none p-2 text-gray-400 dark:text-cleanHomes flex items-start sm:items-center gap-x-2 rounded-lg text-sm sm:text-base border border-gray-200 sm:border-0">
			<information-circle-icon class="w-5 h-5" />
			Selecting multiple days will allow us to assign you a cleaner quicker.
		</div>
		<div v-if="isRegularSelected && (booking.preferred_days.includes('Sat') || booking.preferred_days.includes('Any'))"
			class="warning my-4">
			* Weekend rates will apply
		</div>
		<errors for="preferred_days" />
	</div>
</template>

<script>
	import bookingMixin from '../mixins/bookingMixin';
	import { InformationCircleIcon } from '@heroicons/vue/outline';

	export default {
		components: {InformationCircleIcon},
		mixins: [bookingMixin],
		computed: {
			days() {
				let days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

				if (this.booking.type === 'Domestic') {
					days.push('Saturday');
				}

				return days;
			}
		}
	}
</script>