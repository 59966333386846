<template>
	<modal ref="emailQuoteModal">
		<template #body>
			<transition name="fade" mode="out-in">
				<div v-if="step === 1" class="flex flex-col gap-4 mt-4">
					<h3 class="self-center text-lg text-gray-400">
						Please enter your name and email below so we can can email you a copy of the quote
					</h3>
					<div>
						<input
							id="email_quote_first_name"
							v-model="first_name"
							placeholder="First Name"
							type="text"
							name="first_name"
							class="form-input">
						<errors for="first_name" />
					</div>
					<div>
						<input
							id="email_quote_last_name"
							v-model="last_name"
							placeholder="Last Name"
							type="text"
							name="last_name"
							class="form-input">
						<errors for="last_name" />
					</div>
					<div>
						<input
							id="email_quote_email"
							v-model="email"
							placeholder="Email"
							type="text"
							name="email"
							class="form-input">
						<errors for="email" />
					</div>
					<div v-if="error" class="rounded-md bg-red-50 p-4">
						<div class="flex">
							<div class="flex-shrink-0">
								<!-- Heroicon name: solid/x-circle -->
								<svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20" fill="currentColor"
									aria-hidden="true">
									<path fill-rule="evenodd"
										d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
										clip-rule="evenodd" />
								</svg>
							</div>
							<div class="ml-3">
								<h3 class="text-sm font-medium text-red-800">
									There was an error with your submission
								</h3>
								<div class="mt-2 text-sm text-red-700">
									<ul class="list-disc pl-5 space-y-1">
										<li>
											An unexpected error occurred. Please try again later or consider giving one our friendly staff a call.
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-else>
					<div class="flex flex-col gap-8">
						<h1 class="self-center text-xl text-center">
							Thank You!
						</h1>
						<p class="self-center text-center">
							You should receive an email from us shortly.
						</p>
					</div>
				</div>
			</transition>
		</template>
		<template #footer>
			<button
				v-show="step <= 1"
				class="w-full"
				@click="submit">
				Submit
			</button>
		</template>
	</modal>
</template>

<script>
	import Modal from './Modal'
	import axios from 'axios'
	import bookingMixin from '../mixins/bookingMixin';

	export default {
		components: {
			Modal
		},
		mixins: [bookingMixin],
		data() {
			return {
				first_name: null,
				last_name: null,
				email: null,
				hours: null,
				is_ndis: false,
				is_dva: false,
				suburb_id: null,
				error: null,
				step: 1
			}
		},
		computed: {
			selectedSuburb: {
				get() {
					return this.$store.getters.getSelectedSuburb;
				},
				set(payload) {
					this.$store.commit('setSelectedSuburb', payload);
				},
			},
		},
		methods: {
			open() {
				this.step = 1

				this.$refs.emailQuoteModal.open()
			},
			submit() {
				this.error = false;

				axios.post('/api/send-quote', {
					first_name: this.first_name,
					last_name: this.last_name,
					email: this.email,
					hours: this.booking.hours,
					is_ndis: this.booking.is_ndis,
					is_dva: this.booking.is_dva,
					suburb_id: this.selectedSuburb.id
				}).then(() => {
					this.step++
				}).catch(error => {
					this.parseError(error)

					if (error.response.status !== 422) {
						this.error = true;
						throw error
					}
				})
			}
		}
	}
</script>
