<template>
	<div class="section">
		<h2>Who will pay for the service?</h2>
		<RadioGroup v-model="booking.plan_type">
			<div class="flex flex-wrap gap-4">
				<RadioGroupOption
					v-slot="{ active, checked }"
					as="template"
					value="Agency Managed"
					class="pill"
					style="flex-basis: 144px;">
					<div
						:class="{active, checked}">
						<div class="flex items-center flex-grow">
							<div class="flex-grow text-sm text-center">
								<RadioGroupLabel
									as="p">
									Agency Managed
								</RadioGroupLabel>
							</div>
						</div>
					</div>
				</RadioGroupOption>
				<RadioGroupOption
					id="plan-type-plan-managed"
					v-slot="{ active, checked }"
					as="template"
					value="Plan Managed"
					class="pill"
					style="flex-basis: 144px;">
					<div
						:class="{active, checked}">
						<div class="flex items-center flex-grow">
							<div class="flex-grow text-sm text-center">
								<RadioGroupLabel
									as="p">
									Plan Managed
								</RadioGroupLabel>
							</div>
						</div>
					</div>
				</RadioGroupOption>
			</div>
		</RadioGroup>
		<errors for="plan_type" />
	</div>
</template>

<script>
	import {
		RadioGroup,
		RadioGroupLabel,
		RadioGroupOption,
	} from '@headlessui/vue'

	export default {
		name: 'BookingPlanTypeSelector',
		components: {
			RadioGroup,
			RadioGroupLabel,
			RadioGroupOption,
		},
	}
</script>

<style scoped>

</style>
