<template>
	<div class="section">
		<h2>
			How often will the cleaner be required?
		</h2>
		<RadioGroup v-model="booking.frequency">
			<RadioGroupLabel class="sr-only">
				How often will the cleaner be required?
			</RadioGroupLabel>
			<div class="flex flex-col sm:flex-row gap-4">
				<RadioGroupOption
					v-for="frequency in frequencies"
					:id="`booking-frequency-${frequency.value}`"
					:key="frequency"
					v-slot="{ active, checked }"
					as="template"
					:value="frequency.value"
					class="pill py-2 sm:py-4">
					<div
						:class="{active, checked}">
						<div class="flex items-center flex-grow">
							<div class="flex-grow text-sm text-center">
								<RadioGroupLabel
									as="p">
									{{ frequency.label }}
								</RadioGroupLabel>
							</div>
						</div>
						<div
							v-show="frequency.mostPopular && !isOneoffSelected"
							class="badge"
							:class="{ 'checked': checked }">
							Most Popular
						</div>
					</div>
				</RadioGroupOption>
			</div>
		</RadioGroup>
		<div class="warning my-4">
			* Equipment and products to be provided by the household
		</div>
		<errors for="frequency" />
	</div>
</template>

<script>
	import {
		RadioGroup,
		RadioGroupLabel,
		RadioGroupOption,
	} from '@headlessui/vue'

	const frequencies = [
		{
			value: 'Weekly',
			label: 'Weekly',
			mostPopular: true,
		},
		{
			value: 'Fortnightly',
			label: 'Fortnightly',
		},
		{
			value: 'Bi-weekly',
			label: 'Multi X per week',
		}
	]

	export default {
		name: 'BookingFrequencySection',
		components: {
			RadioGroup,
			RadioGroupLabel,
			RadioGroupOption,
		},
		data() {
			return {
				frequencies
			}
		},
		mounted() {
			this.booking.frequency = this.frequencies[0].value
		}
	}
</script>
