import {createApp} from 'vue'
import {createMetaManager, plugin as metaPlugin} from 'vue-meta';
import App from './App.vue'
import axios from 'axios';
import store from './store/index'
import * as Sentry from '@sentry/vue';
import {BrowserTracing} from '@sentry/browser';
import bookingMixin from './mixins/bookingMixin'
import Errors from './components/ui/Errors.vue';
import mitt from 'mitt'
import {RadioGroup, RadioGroupLabel, RadioGroupOption,} from '@headlessui/vue'
import './assets/tailwind.css'
import {createGtm} from '@gtm-support/vue-gtm';

const app = createApp(App)
const metaManager = createMetaManager();
const country = window.location.origin.endsWith('co.nz') ? 'nz' : 'au'

const eventBus = mitt()

app.config.globalProperties.$eventBus = eventBus

Sentry.init({
	app,
	dsn: process.env.VUE_APP_SENTRY_DSN,
	environment: process.env.NODE_ENV,
	replayssessionsamplerate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	normalizeDepth: 10,
	tracesSampleRate: 0.1,
	allowUrls: [
		'https://book.absolutedomestics.com.au',
		'https://book.absolutedomestics.co.nz',
		'https://book.cleanhomes.com.au',
	],
	integrations: [
		new BrowserTracing(),
		new Sentry.Replay({
			maskAllText: true,
			blockAllMedia: true,
		})
	],
	ignoreErrors: [
		'Sensitive data (like cardNumber) cannot be filled in.',
	]
});

app.config.globalProperties.$sentry = Sentry;

if (process.env.NODE_ENV === 'production') {
	app.use(createGtm({
			id: country === 'au'
				? process.env.VUE_APP_AU_GTM_ID
				: process.env.VUE_APP_NZ_GTM_ID,
			defer: false,
			compatibility: false,
			enabled: true,
			debug: true,
			loadScript: true,
			ignoredViews: ['homepage'],
			trackOnNextTick: false,
		})
	)
}

Date.prototype.addDays = function (days) {
	var date = new Date(this.valueOf());
	date.setDate(date.getDate() + days);
	return date;
};

app.config.globalProperties.$country = country;
app.config.devtools = true;

axios.interceptors.request.use(function (config) {
	const token = sessionStorage.getItem('access_token');

	if (process.env.VUE_APP_CF_ACCESS_CLIENT_ID) {
		config.headers['CF-Access-Client-Id'] = process.env.VUE_APP_CF_ACCESS_CLIENT_ID;
		config.headers['CF-Access-Client-Secret'] = process.env.VUE_APP_CF_ACCESS_CLIENT_SECRET;
	}

	if (!token) {
		return config;
	}

	config.headers['Accept'] = 'application/json';
	config.headers['Authorization'] = `Bearer ${token}`;
	config.headers['Content-Type'] = 'application/json;charset=utf-8';

	if (store.getters['auth/user'] == {}) {
		store.dispatch('auth/fetchUser');
	}

	return config;
});

app.use(store)
app.use(metaManager)
app.use(metaPlugin)
app.use(RadioGroup)
app.use(RadioGroupLabel)
app.use(RadioGroupOption)
app.mixin(bookingMixin)
app.component('errors', Errors)
app.mount('#app')
