<template>
	<div class="section">
		<h2>Special requirements</h2>
		<small>
			Please be aware these comments will be shown to cleaners.
		</small>
		<textarea
			id="comments"
			v-model="booking.comments"
			class="form-textarea"
			rows="3"
			name="comments"
			placeholder="Special Requirements" />
		<errors for="comments" />
	</div>
</template>