<template>
	<div class="section">
		<h1>Additional Information</h1>
		<div class="grid gap-4 md:grid-cols-2">
			<div>
				<div class="relative rounded-md shadow-sm">
					<div class="absolute inset-y-0 left-0 flex items-center px-4 pointer-events-none">
						<bed-icon></bed-icon>
					</div>
					<input
						id="num_bedrooms"
						v-model="booking.num_bedrooms"
						type="number"
						name="num_bedrooms"
						class="block w-full pl-8 py-2"
						style="padding-left: 2.5rem !important;"
						placeholder="How many bedrooms?">
				</div>
				<errors for="num_bedrooms" />
			</div>
			<div>
				<div class="relative rounded-md shadow-sm">
					<div class="absolute inset-y-0 left-0 flex items-center px-4 pointer-events-none">
						<bath-icon></bath-icon>
					</div>
					<input
						id="num_bathrooms"
						v-model="booking.num_bathrooms"
						type="number"
						name="num_bathrooms"
						class="block w-full pl-8 py-2"
						style="padding-left: 2.5rem !important;"
						placeholder="How many bathrooms?">
				</div>
				<errors for="num_bathrooms" />
			</div>
		</div>
	</div>
</template>

<script>
	import BedIcon from './ui/icons/BedIcon.vue'
	import BathIcon from './ui/icons/BathIcon.vue'

	export default {
		components: {
			BedIcon,
			BathIcon
		}
	}
</script>

<style>

</style>
