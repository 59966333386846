<template>
	<div class="card" :class="{ 'selected': selected }"
		@click.stop="onClick">
		<slot :selected="selected" :disabled="disabled"></slot>
	</div>
</template>

<script>
	export default {
		props: {
			selected: {
				type: Boolean,
				default: false
			},
			disabled: {
				type: Boolean,
				default: false
			},
			value: {
				type: [String, Number, Boolean, Object, Array],
				default: () => null
			}
		},
		methods: {
			onClick() {
				if (this.disabled) {
					return;
				}

				if (this.value) {
					this.$emit('input', this.value)
				} else {
					this.$emit('click');
				}
			}
		}
	}
</script>