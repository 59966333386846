import { createStore } from 'vuex';
import { CATCHUP, ONEOFF, REGULAR } from '../mixins/bookingMixin';
import auth from './modules/auth';

const store = createStore({
  modules: {
    auth: auth,
  },
  state: {
    booking: {
      type: 'Domestic',
      suburb: null,
      service: 'Regular',
      start_date: null,
      end_date: null,
      days: 1,
      phone: null,
      comments: '',
      suitability: '',
      is_ndis: null,
      is_dva: null,
      preferred_days: [],
      payment_method: null,
    },
    stripe_token: null,
    use_current_card: null,
    is_guest: null,
    selectedSuburb: {},
    showCommercialFields: false,
    showLoginModal: false,
    currentStep: 0,
    lastStep: 5,
    errors: [],
    apiErrors: [],
    is_submitting: false
  },
  getters: {
    isDomestic: (state) => {
      return state.booking.type === 'Domestic';
    },
    isCommercial: (state) => {
      return state.booking.type === 'Commercial';
    },
    isRegularSelected: (state) => {
      return state.booking.service === REGULAR;
    },
    isCatchupSelected: (state) => {
      return state.booking.service === CATCHUP;
    },
    isOneoffSelected: (state) => {
      return state.booking.service === ONEOFF;
    },
    isRegularEnabled: (state) => {
      return state.selectedSuburb?.regular_enabled;
    },
    isCatchupEnabled: (state) => {
      return state.selectedSuburb?.catchup_enabled;
    },
    isOneoffEnabled: (state) => {
      return state.selectedSuburb?.oneoff_enabled;
    },
    isWindowCleaningEnabled: (state) => {
      return state.selectedSuburb?.window_cleaning_enabled;
    },
    showCommercialFields: (state) => {
      return state.showCommercialFields;
    },
    booking: (state) => {
      return state.booking;
    },
    errors: (state) => {
      return state.errors;
    },
    apiErrors: (state) => {
      return state.apiErrors;
    },
    getSelectedSuburb: (state) => {
      return state.selectedSuburb;
    },
    getStripeToken: (state) => {
      return state.stripe_token;
    },
    shouldUseCurrentCard: (state) => {
      return state.use_current_card;
    },
    isGuest: (state) => {
      return state.is_guest;
    },
    showLoginModal: (state) => {
      return state.showLoginModal;
    },
    currentStep: (state) => {
      return state.currentStep;
    },
    lastStep: (state) => {
      return state.lastStep;
    },
    isSubmitting: (state) => {
      return state.is_submitting;
    }
  },
  mutations: {
    setSelectedSuburb(state, suburb) {
      state.selectedSuburb = suburb;
    },
    setBooking(state, payload) {
      state.booking = payload;
    },
    setShowCommercialFields(state, payload) {
      state.showCommercialFields = payload;
    },
    setDetails(state, payload) {
      state.booking = {
        ...state.booking,
        ...{
          first_name: payload.first_name,
          last_name: payload.last_name,
          email: payload.email,
          phone: payload.phone,
          address: payload.address,
        },
      };
    },
    setStripeToken(state, payload) {
      state.stripe_token = payload;
    },
    setUseCurrentCard(state, payload) {
      state.use_current_card = payload;
    },
    setErrors(state, payload) {
      state.errors = payload;
    },
    setApiErrors(state, payload) {
      state.apiErrors = payload;
    },
    setIsGuest(state, payload) {
      state.is_guest = payload;
    },
    setShowLoginModal(state, payload) {
      state.showLoginModal = payload;
    },
    setCurrentStep(state, payload) {
      state.currentStep = payload;
    },
    setLastStep(state, payload) {
      state.lastStep = payload;
    },
    setIsSubmitting(state, payload) {
      state.is_submitting = payload;
    }
  },
});

export default store;
