
<template>
	<div
		id="service-commercial"
		class="section">
		<h2>What type of service is this?</h2>
		<RadioGroup v-model="booking.commercial_type">
			<div class="flex flex-wrap gap-4">
				<RadioGroupOption
					id="booking-is-ndis"
					v-slot="{ active, checked }"
					as="template"
					value="NDIS"
					class="pill"
					style="flex-basis: 144px;">
					<div
						:class="{active, checked}">
						<div class="flex items-center flex-grow">
							<div class="flex-grow text-sm text-center">
								<RadioGroupLabel
									as="p">
									NDIS
								</RadioGroupLabel>
							</div>
						</div>
					</div>
				</RadioGroupOption>
				<RadioGroupOption
					id="booking-is-dva"
					v-slot="{ active, checked }"
					as="template"
					value="DVA"
					class="pill"
					style="flex-basis: 144px;">
					<div
						:class="{active, checked}">
						<div class="flex items-center flex-grow">
							<div class="flex-grow text-sm text-center">
								<RadioGroupLabel
									as="p">
									DVA
								</RadioGroupLabel>
							</div>
						</div>
					</div>
				</RadioGroupOption>
				<RadioGroupOption
					id="booking-is-other"
					v-slot="{ active, checked }"
					as="template"
					value="Other"
					class="pill"
					style="flex-basis: 144px;">
					<div
						:class="{active, checked}">
						<div class="flex items-center flex-grow">
							<div class="flex-grow text-sm text-center">
								<RadioGroupLabel
									as="p">
									Other
								</RadioGroupLabel>
							</div>
						</div>
					</div>
				</RadioGroupOption>
			</div>
		</RadioGroup>
		<errors for="is_ndis" />
		<errors for="is_dva" />
	</div>
</template>

<script>
	import {
		RadioGroup,
		RadioGroupLabel,
		RadioGroupOption,
	} from '@headlessui/vue'
	import bookingMixin from '../mixins/bookingMixin'

	export default {
		name: 'CommercialBookingType',
		components: {
			RadioGroup,
			RadioGroupLabel,
			RadioGroupOption,
		},
		mixins: [bookingMixin],
		data() {
			return {
				commercial_type: null,
			}
		},
		watch: {
			'booking.commercial_type': function (val) {
				console.log('commercial booking type changed');
				this.booking.payment_method = 'Cash';

				if (val === 'NDIS') {
					this.booking.is_ndis = true;
					this.booking.is_dva = false;
					return;
				}

				if (val === 'DVA') {
					this.booking.is_dva = true;
					this.booking.is_ndis = false;
					return;
				}

				this.booking.is_ndis = false;
				this.booking.is_dva = false;
			}
		}
	}
</script>

<style scoped>

</style>
