<template>
	<div>
		<div class="section">
			<h2>How many hours will your cleaner be required?</h2>
			<RadioGroup v-model="booking.hours">
				<RadioGroupLabel class="sr-only">
					How many hours will your cleaner be required?
				</RadioGroupLabel>
				<div class="flex flex-wrap gap-4">
					<RadioGroupOption
						v-for="i in [1, 1.5, 2, 2.5, 3, 3.5, 4]"
						v-show="i + 1 >= baseHours"
						:id="`booking-hours-${i + 1}`"
						:key="`${i + 1}-hours`"
						v-slot="{ active, checked }"
						as="template"
						:value="i + 1"
						class="flex-1 pill"
						style="flex-basis: 112px;">
						<div
							:class="{active, checked}">
							<div class="flex items-center flex-grow">
								<div class="flex-grow text-sm text-center">
									<RadioGroupLabel
										as="p">
										<strong>{{ i + 1 < 5 ? i + 1 : `${i + 1}+` }}</strong> hours
									</RadioGroupLabel>
								</div>
							</div>
							<div
								v-show="i + 1 === 3 && !isOneoffSelected"
								class="badge"
								:class="{ 'checked': checked }">
								Most Popular
							</div>
						</div>
					</RadioGroupOption>
				</div>
			</RadioGroup>
			<errors for="hours" />
		</div>
		<div
			v-if="booking.service === REGULAR"
			class="section">
			<h2 v-if="isDomestic">
				How many days per week would you like the clean to occur?
			</h2>
			<h2 v-if="isCommercial">
				How many days per week should the clean occur?
			</h2>
			<div
				class="flex gap-4 justify-center sm:justify-start p-1 border-primary border rounded lg:w-1/2 bg-white sm:bg-none">
				<button
					class="bg-primary text-white rounded active:ring-2 active:ring-primary transition-none"
					@click="setDays(booking.days - 1)">
					-
				</button>
				<input
					v-model="booking.days"
					class="w-24 rounded-lg border-none text-center form-input"
					type="number"
					max="7"
					min="1">
				<button
					class="bg-primary text-white rounded active:ring-2 active:ring-primary transition-none"
					@click="setDays(booking.days + 1)">
					+
				</button>
			</div>
			<errors for="days" />
		</div>
	</div>
</template>

<script>
	import 'flatpickr/dist/flatpickr.css'
	import {
		RadioGroup,
		RadioGroupLabel,
		RadioGroupOption,
	} from '@headlessui/vue'
	import bookingMixin from '../mixins/bookingMixin'

	export default {
		name: 'BookingHoursSelector',
		components: {
			RadioGroup,
			RadioGroupLabel,
			RadioGroupOption,
		},
		mixins: [bookingMixin],
		computed: {
			baseHours() {
				switch (this.booking.service) {
					case this.REGULAR:
						return 2
					case this.CATCHUP:
						return 2
					case this.ONEOFF:
						return 3
					case this.WINDOW_CLEANING:
						return 2
					default:
						return 0
				}
			},
		},
		created() {
			if (!this.booking.hours) {
				this.booking.hours = this.baseHours
			}
		},
		methods: {
			setDays(number) {
				if (number < 1 || number > 7) {
					return;
				}

				this.booking.days = number;
			}
		}
	}
</script>
