<template>
	<svg xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg"
		class="icon icon-bed" width="16"
		height="16">
		<defs>
			<symbol id="icon-bed" viewBox="0 0 32 32">
				<path
					d="M2.667 21.445v-5.993h19.449c2.852 0 5.172 2.052 5.172 4.573v1.42h-24.621zM6.113 10.955c1.842 0 3.466 0.763 4.229 1.831h-7.676v-1.831h3.446zM22.115 12.786h-8.837c-0.765-2.574-3.683-4.498-7.165-4.498h-3.446v-2.688c0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333v0 24.038c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v0-5.526h24.621v5.526c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v0-9.613c0-3.991-3.517-7.239-7.839-7.239z"></path>
			</symbol>
		</defs>
		<use xlink:href="#icon-bed"></use>
	</svg>
</template>
