<template>
	<div>
		<TransitionRoot appear :show="visible"
			as="template">
			<Dialog as="div" @close="close">
				<div class="fixed inset-0 z-10 overflow-y-auto">
					<div class="min-h-screen px-4 text-center">
						<TransitionChild
							as="template"
							enter="duration-300 ease-out"
							enter-from="opacity-0"
							enter-to="opacity-100"
							leave="duration-200 ease-in"
							leave-from="opacity-100"
							leave-to="opacity-0">
							<DialogOverlay class="fixed inset-0 bg-black bg-opacity-50" />
						</TransitionChild>

						<span class="inline-block h-screen align-middle" aria-hidden="true">
							&#8203;
						</span>

						<TransitionChild
							as="template"
							enter="duration-300 ease-out"
							enter-from="opacity-0 scale-95"
							enter-to="opacity-100 scale-100"
							leave="duration-200 ease-in"
							leave-from="opacity-100 scale-100"
							leave-to="opacity-0 scale-95">
							<div
								class="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
								<a class="absolute top-5 right-5 cursor-pointer"
									@click="close">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z"
											fill="currentColor" />
									</svg>
								</a>
								<div class="px-4 pt-5 pb-4 mb-8 bg-white sm:p-6 sm:pb-4">
									<slot name="body"></slot>
								</div>
								<div v-if="$slots.footer" class="px-4 py-3 bg-white sm:px-6 sm:flex sm:flex-row-reverse">
									<slot name="footer"></slot>
								</div>
							</div>
						</TransitionChild>
					</div>
				</div>
			</Dialog>
		</TransitionRoot>
	</div>
</template>

<script>
	import {
		TransitionRoot,
		TransitionChild,
		Dialog,
		DialogOverlay,
	} from '@headlessui/vue'

	export default {
		components: {
			TransitionRoot,
			TransitionChild,
			Dialog,
			DialogOverlay,
		},
		data() {
			return {
				visible: false
			}
		},
		methods: {
			open() {
				this.visible = true
			},
			close() {
				this.visible = false
			}
		}
	}
</script>

<style scoped>
.gg-close {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 40px
}
.gg-close::after,
.gg-close::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 16px;
  height: 2px;
  background: currentColor;
  transform: rotate(45deg);
  border-radius: 5px;
  top: 8px;
  left: 1px
}
.gg-close::after {
  transform: rotate(-45deg)
}
</style>
