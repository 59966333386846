<template>
	<div class="section">
		<h2>Select a cleaning service</h2>
		<div class="services">
			<selectable-card
				id="service-regular"
				:disabled="!isRegularEnabled"
				:selected="isRegularSelected"
				class="service"
				@click="selectService(REGULAR)">
				<template #default="{ selected }">
					<div class="name">
						Weekly or Fortnightly
						<template v-if="extraHourFreePromotionEnabled">
							<div
								class="h-5 bg-offwhite px-4 text-xs absolute inline-flex items-center rounded text-primary border-primary border -bottom-2 right-4 shadow-md lg:-top-2 lg:text-[14px] lg:h-6 xl:hidden">
								* Extra Hour Free on your first clean
							</div>
							<div
								class="h-5 bg-offwhite px-4 text-xs hidden xl:inline-flex items-center rounded text-primary border-primary border">
								* Extra Hour Free on your first clean
							</div>
						</template>
					</div>
					<p class="description">
						Claim your weekends back.
						<br>
						Let us clean the house.
						<br>
						<br>
					</p>
					<div class="rates">
						<div class="flex flex-col md:flex-row md:items-end justify-center gap-x-2">
							<span
								class="text-xl font-bold"
								:class="[selected ? 'text-primary' : 'text-gray-400']">
								${{ getBaseRate(REGULAR) }}
							</span>
							<div class="uppercase text-xs">
								for <span
									class="text-lg px-1"
									:class="[selected ? 'text-primary' : 'text-gray-400']">{{ getMinimumHours(REGULAR) }}</span> hours
							</div>
						</div>
						<div class="text-xs">
							${{ getHourlyRate(REGULAR) }}/hour thereafter
						</div>
					</div>
					<button
						class="button uppercase"
						:class="{selected}">
						{{ selected ? 'Selected' : 'Select' }}
					</button>
					<div
						v-show="!isRegularEnabled"
						class="absolute top-0 w-max text-xs py-0.5 bg-gray-100 text-primary border border-primary px-2 rounded left-1/2 transform -translate-x-1/2 -translate-y-1/2">
						Not available at the moment
					</div>
				</template>
			</selectable-card>
			<selectable-card
				id="service-oneoff"
				:disabled="!isOneoffEnabled"
				:selected="isOneoffSelected"
				class="service"
				@click="selectService(ONEOFF)">
				<template #default="{ selected }">
					<div class="name">
						Move Out/Spring Clean
					</div>
					<p class="description">
						Our spring clean takes the hard work out of a big cleaning job by using
						one of our expert one-off cleaners.
					</p>
					<div class="rates">
						<div class="flex flex-col md:flex-row md:items-end justify-center gap-x-2">
							<span
								class="text-xl font-bold"
								:class="[selected ? 'text-primary' : 'text-gray-400']">
								${{ getBaseRate(ONEOFF) }}
							</span>
							<div class="uppercase text-xs">
								for <span
									class="text-lg px-1"
									:class="[selected ? 'text-primary' : 'text-gray-400']">{{ getMinimumHours(ONEOFF) }}</span> hours
							</div>
						</div>
						<div class="text-xs">
							${{ getHourlyRate(ONEOFF) }}/hour thereafter
						</div>
					</div>
					<button
						class="button uppercase"
						:class="{selected}">
						{{ selected ? 'Selected' : 'Select' }}
					</button>
					<div
						v-show="!isOneoffEnabled"
						class="absolute top-0 w-max text-xs py-0.5 bg-gray-100 text-primary border border-primary px-2 rounded left-1/2 transform -translate-x-1/2 -translate-y-1/2">
						Not available at the moment
					</div>
				</template>
			</selectable-card>
			<selectable-card
				v-if="isDomestic && isAU"
				id="service-commercial"
				class="service"
				@click="selectCommercial">
				<template #default="{ selected }">
					<div class="name">
						NDIS, Veterans Affairs, Workers Comp, Insurance
					</div>
					<p class="description">
						Domestic Cleaning services for people with a disability, aged card and insurance claims.
					</p>
					<div class="rates">
						<div class="flex items-center justify-center h-11">
							<span
								class="text-md font-bold uppercase"
								:class="[selected ? 'text-primary' : 'text-gray-400']">
								Specific Rates Apply
							</span>
						</div>
					</div>
					<button
						class="button uppercase"
						:class="{selected}">
						{{ selected ? 'Selected' : 'Select' }}
					</button>
				</template>
			</selectable-card>
		</div>
		<errors for="service" />
	</div>
</template>

<script>
	import bookingMixin from '../mixins/bookingMixin';
	import SelectableCard from './ui/SelectableCard.vue';

	export default {
		name: 'BookingServiceSelector',
		components: {
			SelectableCard,
		},
		mixins: [bookingMixin],
		methods: {
			selectCommercial() {
				this.booking.type = 'Commercial';
				this.booking.preferred_days = [];
				this.booking.payment_method = 'Cash'
				this.booking.service = this.REGULAR
			},
			selectService(service) {
				switch (service) {
					case this.REGULAR:
						if (!this.isRegularEnabled) return
						break
					case this.ONEOFF:
						if (!this.isOneoffEnabled) return
						break
					case this.CATCHUP:
						if (!this.isCatchupEnabled) return
						break
				}

				this.booking.service = service
				this.booking.hours = this.getMinimumHours(service);

				if (this.booking.service !== this.ONEOFF) {
					this.booking.oneoff_type = null;
				}

				this.$store.commit('setIsGuest', true)

				if (this.booking.service === this.ONEOFF) {
					this.booking.payment_method = 'Cash'
				}
			}
		}
	}
</script>

<style scoped>
.services {
		@apply grid grid-cols-1 xl:grid-cols-3
}

.service {
	display: grid;
	grid-template-columns: 1fr 0.5fr 1fr;
	grid-template-rows: 1fr;
	grid-template-areas:
		"name name rates"
		"description description rates"
		"button button rates";
	@apply flex-1 rounded-lg;
}

.service {
		@apply lg:grid-cols-1 lg:grid-rows-4 lg:gap-y-4 bg-white text-gray-400;
}

@media (min-width: 1024px) {
	.service {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		grid-template-areas:
			"name"
			"description"
			"rates"
			"button";
	}
}

.service.selected {
	@apply ring-1 ring-primary text-gray-700;
}

.service .name {
	grid-area: name;
	@apply text-lg font-semibold;
}

.service .description {
	grid-area: description;
	@apply text-sm;
}

.service .rates {
	grid-area: rates;
	@apply text-center border rounded-lg p-2 h-auto flex flex-col items-center justify-center ml-2;
}

.service .button {
	grid-area: button;
		@apply text-xs mt-2 mr-4 lg:mr-0 rounded-lg bg-white  border active:border-primary active:text-primary text-gray-400 border-gray-200;
}

.service .button.selected {
	@apply bg-primary text-white;
}
</style>
