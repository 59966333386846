<template>
	<div>
		<div class="flex justify-center mt-4 lg:mt-16 md:gap-x-4 mb-96 h-min">
			<div class="flex-grow flex flex-col">
				<div class="sm:bg-white rounded-lg sm:border p-4 h-full">
					<nav
						v-if="!isCommercial && currentStep !== 0"
						aria-label="Progress">
						<ol
							role="list"
							class="flex">
							<li
								v-for="step in lastStep"
								:key="`step-${step}`"
								class="relative"
								:class="{
									'flex-1 pr-8 sm:pr-20': step !== lastStep,
									'flex': step === lastStep,
								}">
								<div
									v-if="step !== lastStep"
									class="absolute inset-0 flex items-center"
									aria-hidden="true">
									<div
										class="h-0.5 w-full"
										:class="{
											'bg-primary': step < currentStep,
											'bg-gray-200': step >= currentStep,
										}">
									</div>
								</div>
								<a
									href="#"
									class="relative flex h-5 w-5 items-center justify-center rounded-full accent-primary"
									:class="{
										'bg-primary': step < currentStep,
										'border border-primary bg-offwhite': step === currentStep,
										'border border-gray-300 bg-offwhite': step > currentStep,
										'bg-red-500 border-red-500 ring-4 ring-red-500 ring-opacity-50': getErrorsForStep(step).length > 0,
									}"
									@click="goToStep(step)">
									<span class="sr-only">Step {{ step }}</span>
									<span
										v-if="getErrorsForStep(step).length > 0"
										class="absolute h-2 w-2 rounded-full animate-ping bg-red-100"
										aria-hidden="true"></span>
								</a>
							</li>
						</ol>
					</nav>
					<div
						v-if="currentStep === 0 || isCommercial"
						class="section">
						<h2>Enter your suburb/postcode</h2>
						<VueMultiselect
							ref="suburb_select"
							v-model="suburb"
							class="booking-form-suburb-select"
							:options="suburbs"
							placeholder="Start typing to find your suburb"
							label="name"
							track-by="id"
							:internal-search="false"
							:clear-on-select="false"
							:preserve-search="true"
							@select="whenSuburbSelected"
							@remove="resetSuburb"
							@search-change="getSuburbs"
							@open="whenSuburbSelectOpened">
							<template #option="suburb">
								<span class="font-bold">{{ suburb.option.name }}</span> ({{ suburb.option.state }}
								{{ suburb.option.postcode }})
							</template>
						</VueMultiselect>
					</div>
					<div v-if="selectedSuburb?.id">
						<commercial-booking-form v-if="isCommercial" />
						<domestic-booking-form v-if="isDomestic" />
					</div>
					<template v-if="showSubmitButton && isCommercial">
						<div
							class="warning"
							@click="booking.agree_terms = !booking.agree_terms">
							<div class="relative flex items-start">
								<div class="flex items-center h-5">
									<input
										id="agree_terms"
										v-model="booking.agree_terms"
										aria-describedby="agree_terms-description"
										value="1"
										name="agree_terms"
										type="checkbox"
										class="form-checkbox" />
								</div>
								<div class="ml-3 text-sm">
									I confirm that the relevant insurer/payer has approved payment of this service for the period entered in
									this form. I confirm that payment will be made within 7 days of the invoice.
								</div>
							</div>
						</div>
					</template>
				</div>
				<div class="hidden lg:flex justify-between px-8 mt-4">
					<button
						v-if="showBackButton"
						id="go-back"
						class="back-button w-64"
						tabindex="-1"
						@click="goToPreviousStep">
						Go Back
					</button>
					<button
						v-if="showForwardButton"
						id="go-to-next-step"
						class="forward-button w-64 ml-auto"
						@click="goToNextStep">
						Continue
					</button>
				</div>
			</div>
			<div class="sticky flex-shrink hidden top-10 lg:block">
				<booking-overview></booking-overview>
			</div>
			<div class="fixed bottom-0 lg:hidden flex w-full">
				<booking-overview class="flex-grow" />
			</div>
		</div>
		<email-quote-modal ref="emailQuoteModal"></email-quote-modal>
		<login-modal ref="loginModal"></login-modal>
		<modal ref="bookingFormErrorModal">
			<template #body>
				<div class="space-y-4 text-center">
					<h1>
						An internal server error occurred
					</h1>
					<p>
						Please try again or call our friendly team on
						<a class="text-primary">{{ phone }}</a>
					</p>
				</div>
			</template>
		</modal>
		<modal ref="noServicesAvailableModal">
			<template #body>
				<div class="space-y-4 text-center">
					<h1>We're sorry!</h1>
					<p>
						We don't currently offer any services in your area.
					</p>
					<p>
						If you would like to know more, please call our friendly team on
						<a class="text-primary">{{ phone }}</a>
					</p>
				</div>
			</template>
		</modal>
	</div>
</template>

<script>
	import axios from 'axios'
	import BookingOverview from './BookingOverview.vue'
	import Modal from './Modal'
	import VueMultiselect from 'vue-multiselect'
	import 'vue-multiselect/dist/vue-multiselect.css'
	import { parse } from 'query-string'
	import EmailQuoteModal from './EmailQuoteModal';
	import LoginModal from './LoginModal';
	import bookingMixin from '../mixins/bookingMixin';
	import CommercialBookingForm from './CommercialBookingForm.vue';
	import DomesticBookingForm from './DomesticBookingForm.vue';

	const urlify = (string) => {
		return string.trim().replace(/\s/g, '%20')
	}

	axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

	export default {
		components: {
			VueMultiselect,
			BookingOverview,
			EmailQuoteModal,
			CommercialBookingForm,
			DomesticBookingForm,
			LoginModal,
			Modal,
		},
		mixins: [bookingMixin],
		data() {
			return {
				suburb: null,
				submitting: false,
				suburbs: [],
				cancelToken: null,
				proceed_with_booking: null,
				checkout_as_guest: null,
				username: null,
				password: null,
			}
		},
		computed: {
			showAccountSelector() {
				return false;
			},
			showSuburbSelector() {
				return true;
			}
		},
		async mounted() {
			this.$eventBus.on('bookingFormErrorModal.open', () => {
				this.$refs.bookingFormErrorModal.open();
			});

			let query = parse(location.search)

			if (query.suburb_id) {
				await this.getSuburb(query.suburb_id)
			}

			this.$store.commit('setIsGuest', true)
			if (process.env.VUE_APP_CLEAN_HOMES === 'true') {
				this.booking.type = 'Commercial';
				this.booking.service = 'Regular';
			}
		},
		methods: {
			async getSuburbs(query) {
				if (!query) return []
				if (this.cancelToken) this.cancelToken.cancel()

				const CancelToken = axios.CancelToken
				const queryString = urlify(query)

				this.cancelToken = CancelToken.source()

				return axios.get(`/api/suburbs/list/${queryString}`, {
					cancelToken: this.cancelToken.token,
					params: {
						country: this.country
					}
				}).then((response) => {
					this.suburbs = response.data
				}).catch((error) => {
					if (axios.isCancel(error)) {
						return;
					}

					throw error;
				})
			},
			async getSuburb(id) {
				return axios.get(`/api/suburbs/id/${id}`).then((response) => {
					this.suburb = response.data;
					this.setSuburb(response.data);
				});
			},
			async whenSuburbSelected(suburb) {
				if (!suburb) return;

				await this.setSuburb(suburb);

				this.updateQueryString({
					suburb_id: suburb.id,
				});
			},
			async setSuburb(suburb) {
				this.$store.commit('setSelectedSuburb', suburb);
				this.$store.commit('setIsGuest', true)

				if (!this.selectedSuburb.credit_cards_enabled) {
					this.booking.payment_method = 'Cash'
				}

				if (!this.isRegularEnabled && !this.isCatchupEnabled && !this.isOneoffEnabled) {
					this.$refs.noServicesAvailableModal.open()
				}

				if (this.isDomestic) {
					this.setCurrentStep(1);
				}
			},
			resetSuburb() {
				this.suburb = null;
				this.$store.commit('setSelectedSuburb', {});
				this.$store.commit('setIsGuest', true);

				window.history.pushState({}, document.title, window.location.pathname);
			},
			whenSuburbSelectOpened() {
				this.$nextTick(() => {
					if (!this.suburb) return;

					this.$refs.suburb_select.search = this.suburb.name
				});
			}
		}
	}
</script>
