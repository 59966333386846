<template>
	<div>
		<template v-if="currentStep === 1">
			<booking-service-selector />
		</template>
		<template v-if="currentStep === 2">
			<booking-recipient-details-section />
		</template>
		<template v-if="currentStep === 3">
			<booking-oneoff-type-selector v-if="isOneoffSelected" />
			<booking-frequency-section v-if="isRegularSelected" />
			<booking-hours-selector />
		</template>
		<template v-if="currentStep === 4">
			<booking-days-selector />
			<booking-date-selector />
			<booking-bed-bath-selector v-if="isOneoffSelected" />
			<booking-additional-questions />
		</template>
		<template v-if="currentStep === 5">
			<booking-payment @submit="submit" />
		</template>
	</div> 
</template>

<script>
	import bookingMixin from '../mixins/bookingMixin';
	import BookingServiceSelector from './BookingServiceSelector.vue';
	import BookingFrequencySection from './BookingFrequencySection.vue';
	import BookingOneoffTypeSelector from './BookingOneoffTypeSelector.vue';
	import BookingHoursSelector from './BookingHoursSelector.vue';
	import BookingAdditionalQuestions from './BookingAdditionalQuestions.vue';
	import BookingDaysSelector from './BookingDaysSelector.vue';
	import BookingDateSelector from './BookingDateSelector.vue';
	import BookingRecipientDetailsSection from './BookingRecipientDetailsSection.vue';
	import BookingBedBathSelector from './BookingBedBathSelector.vue';
	import BookingPayment from './BookingPayment.vue';

	export default {
		components: {
			BookingServiceSelector,
			BookingFrequencySection,
			BookingOneoffTypeSelector,
			BookingHoursSelector,
			BookingAdditionalQuestions,
			BookingDaysSelector,
			BookingDateSelector,
			BookingRecipientDetailsSection,
			BookingBedBathSelector,
			BookingPayment,
		},
		mixins: [bookingMixin],
	}
</script>