<template>
	<div>
		<div class="section xl:w-3/5 mx-auto">
			<div class="flex flex-col gap-y-2 mt-8">
				<h2 class="text-center mb-3">
					Book now, <span class="text-primary">Pay on the Day</span>
					<br>
					Choose your payment method
				</h2>
				<RadioGroup
					v-if="canUseCreditCard"
					v-model="booking.payment_method">
					<RadioGroupLabel class="sr-only">
						How would you like to pay?
					</RadioGroupLabel>
					<div class="grid grid-cols-2 gap-x-4">
						<RadioGroupOption
							v-if="canUseCreditCard"
							:id="`booking-payment-method-card`"
							:key="`booking-payment-method-card`"
							v-slot="{ checked }"
							as="div"
							value="Credit Card"
							class="method">
							<RadioGroupLabel
								as="div"
								class="flex font-bold text-xs sm:text-base items-center">
								<CreditCardIcon
									class="w-4 h-4 sm:w-6 sm:h-6 mr-2 mb-2 sm:mb-0"
									:class="{ 'text-white': checked }" />
								Card
							</RadioGroupLabel>
						</RadioGroupOption>
						<RadioGroupOption
							:id="`booking-payment-method-cash`"
							:key="`booking-payment-method-cash`"
							v-slot="{ checked }"
							as="div"
							value="Cash"
							class="method">
							<RadioGroupLabel
								as="div"
								class="flex font-bold text-xs sm:text-base items-center">
								<BankIcon
									class="w-4 h-4 sm:w-6 sm:h-6 mr-2 mb-2 sm:mb-0"
									:class="{ 'text-white': checked }" />
								Cash or Bank Transfer
							</RadioGroupLabel>
						</RadioGroupOption>
					</div>
				</RadioGroup>
				<errors
					for="payment_method"
					class="flex justify-center" />
				<hr
					v-if="booking.payment_method === 'Credit Card'"
					class="my-2">
				<div v-if="booking.payment_method === 'Credit Card'">
					<template v-if="canUseCreditCard && booking.payment_method === 'Credit Card'">
						<StripeElements
							v-slot="{ elements }"
							ref="stripeElements"
							:stripe-key="stripeKey">
							<div>
								<div class="flex justify-between items-center">
									<label class="pb-2">Card number</label>
									<div class="flex relative right-0 top-[38px]">
										<img
											src="/svgs/visa.svg"
											class="w-10 h-6" />
										<img
											src="/svgs/mastercard.svg"
											class="w-10 h-6" />
										<img
											src="/svgs/amex.svg"
											class="w-10 h-6" />
									</div>
								</div>
								<StripeElement
									id="cardNumber"
									ref="cardElement"
									class="p-2 border rounded-lg px-4 mb-4 bg-white"
									type="cardNumber"
									:elements="elements"
									:options="cardOptions">
								</StripeElement>
							</div>
							<div class="flex justify-between">
								<div class="w-full">
									<label class="block pb-2">Expiration</label>
									<StripeElement
										id="cardExpiry"
										class="p-2 border rounded-lg px-4 bg-white"
										type="cardExpiry"
										:elements="elements"
										:options="cardOptions" />
								</div>
								<div class="mx-2"></div>
								<div class="w-full">
									<label class="block pb-2">CVC</label>
									<StripeElement
										id="cardCvc"
										class="p-2 border rounded-lg px-4 bg-white"
										type="cardCvc"
										:elements="elements"
										:options="cardOptions" />
								</div>
							</div>
						</StripeElements>
					</template>
					<div
						v-if="booking.payment_method === 'Credit Card'"
						class="flex gap-x-2 items-center justify-center text-sm text-gray-400 h-8 my-2">
						<lock-closed-icon class="w-4 h-4 text-gray-400" />
						Safe and Secure Payments
						<powered-by-stripe class="w-24 h-24 text-gray-400 fill-gray-400" />
					</div>
				</div>
			</div>
			<p class="text-center mt-4">
				Your cleaner will process payment only once the job is complete.
			</p>
			<booking-submit-button @submit="handleSubmit" />
		</div>
	</div>
</template>

<script>
	import {StripeElements, StripeElement} from 'vue-stripe-js'
	import {loadStripe} from '@stripe/stripe-js'
	import {defineComponent, ref, onBeforeMount, computed} from 'vue'
	import {mapActions, mapGetters, mapMutations, useStore} from 'vuex';
	import BookingSubmitButton from './BookingSubmitButton';
	import {
		RadioGroup,
		RadioGroupLabel,
		RadioGroupOption,
	} from '@headlessui/vue'
	import {LockClosedIcon} from '@heroicons/vue/solid'
	import CreditCardIcon from './ui/icons/CreditCardIcon.vue';
	import BankIcon from './ui/icons/BankIcon.vue';
	import PoweredByStripe from './ui/icons/PoweredByStripe.vue';
	import bookingMixin from '../mixins/bookingMixin';

	export default defineComponent({
		name: 'CardOnly',
		components: {
			BookingSubmitButton,
			StripeElements,
			StripeElement,
			RadioGroup,
			RadioGroupLabel,
			RadioGroupOption,
			LockClosedIcon,
			CreditCardIcon,
			BankIcon,
			PoweredByStripe
		},
		mixins: [bookingMixin],
		setup() {
			const stripeKey = ref(process.env.VUE_APP_AU_STRIPE_KEY)
			const stripeLoaded = ref(false)
			const card = ref()
			const elements = ref()
			const stripeResponse = ref({})
			const use_current_card = ref()
			const is_editing_card = ref(false);
			const cardOptions = {
				value: {
					postalCode: '',
				},
				appearance: {
					theme: 'night',
					labels: 'floating',
				},
				style: {
					base: {
						padding: '32px',
						fontSize: '16px',
						lineHeight: '24px',
						'::placeholder': {
							color: '#aab7c4',
						}
					}
				}
			}

			onBeforeMount(() => {
				const stripePromise = loadStripe(stripeKey.value)

				stripePromise.then(() => {
					stripeLoaded.value = true
				}).catch();
			})

			const store = useStore()

			const booking = computed(() => store.state.booking)

			return {
				stripeKey,
				stripeResponse,
				stripeLoaded,
				card,
				use_current_card,
				is_editing_card,
				elements,
				booking,
				cardOptions,
				...mapActions({
					fetchUser: 'auth/fetchUser'
				}),
				...mapMutations([
					'setStripeToken'
				]),
				...mapGetters([
					'getStripeToken',
				]),
				...mapGetters({
					getToken: 'auth/getToken'
				}),
			}
		},
		methods: {
			createCard() {
				return new Promise((resolve, reject) => {
					const groupComponent = this.$refs.stripeElements
					const cardComponent = this.$refs.cardElement
					const cardElement = cardComponent.stripeElement

					groupComponent.instance.createToken(cardElement).then((result) => {
						this.stripeResponse = result

						if (result.token) {
							resolve(result);
						}

						reject(result);
					})
				});
			},
			async handleSubmit() {
				const valid = await this.validate();

				if (!valid) {
					return;
				}

				this.setIsSubmitting(true);

				if (this.booking.payment_method !== 'Credit Card') {
					this.$emit('submit')
					return;
				}

				try {
					const response = await this.createCard();

					this.setStripeToken(response.token.id);
					this.$emit('submit');
				} catch (error) {
					if (error && error.error) {
						this.setApiErrors({
							stripe: [error.error.message]
						})
					}

					this.setIsSubmitting(false);

					throw error;
				}
			}
		}
	})
</script>

<style scoped>
.method {
	@apply flex items-center justify-between p-4 border border-gray-200 rounded-md text-gray-400 bg-white;
}

.method[data-headlessui-state*="checked"] {
	@apply bg-primary text-white;
}

.StripeElement {
	background-color: red !important;
	padding: 8px 8px;
	border-radius: 4px;
	border: 1px solid red !important;
	box-shadow: 0 1px 3px 0 #e6ebf1;
	-webkit-transition: box-shadow 150ms ease;
	transition: box-shadow 150ms ease;
	margin-bottom: 20px;
	color: black;
}

.ElementsApp,
.ElementsApp .InputElement {
	color: black;
	background: red !important;
}

.StripeElement--focus {
	box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
	border-color: #fa755a;
}

.StripeElement--webkit-autofill {
	background-color: #fefde5 !important;
}
</style>
