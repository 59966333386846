<template>
	<div class="section">
		<h2 v-if="isDomestic">
			Your Details*
		</h2>
		<h2 v-if="isCommercial">
			Who is having the cleaning (participant)?
		</h2>
		<div class="grid gap-4 md:grid-cols-6">
			<div class="col-span-6 md:col-span-3">
				<input
					id="first_name"
					v-model="booking.first_name"
					placeholder="First Name"
					name="first_name"
					class="form-input">
				<errors for="first_name" />
			</div>
			<div class="col-span-6 md:col-span-3">
				<input
					id="last_name"
					v-model="booking.last_name"
					placeholder="Last Name"
					name="last_name"
					class="form-input">
				<errors for="last_name" />
			</div>
			<div class="col-span-6 md:col-span-3">
				<input
					id="email"
					v-model="booking.email"
					type="email"
					placeholder="Email"
					name="email"
					class="form-input">
				<errors for="email" />
			</div>
			<div class="col-span-6 md:col-span-3">
				<input
					id="phone"
					v-model="booking.phone"
					name="phone"
					placeholder="Phone"
					class="form-input"
					@input="formatPhone">
				<errors for="phone" />
			</div>
			<div class="col-span-6">
				<input
					id="address"
					v-model="booking.address"
					placeholder="Address"
					name="address"
					class="form-input">
				<errors for="address" />
			</div>
			<div class="col-span-6 lg:col-span-2">
				<input
					id="suburb"
					v-model="selectedSuburb.name"
					placeholder="suburb"
					disabled
					name="suburb"
					class="form-input">
				<errors for="suburb" />
			</div>
			<div class="col-span-3 lg:col-span-2">
				<input
					id="state"
					v-model="selectedSuburb.state"
					placeholder="State"
					disabled
					name="state"
					class="text-center md:text-left form-input">
				<errors for="state" />
			</div>
			<div class="col-span-3 lg:col-span-2">
				<input
					id="postcode"
					v-model="selectedSuburb.postcode"
					placeholder="Postcode"
					disabled
					name="postcode"
					class="text-center md:text-left form-input">
				<errors for="postcode" />
			</div>
			<div v-if="booking.is_ndis" class="col-span-3">
				<input
					id="ndis_number"
					v-model="booking.ndis_number"
					placeholder="NDIS ID"
					name="ndis_number"
					class="form-input">
				<errors for="ndis_number" />
			</div>
			<div v-if="booking.is_ndis" class="col-span-3">
				<div id="bookingDobContainer" class="flex-1">
					<flat-pickr
						id="booking-dob"
						v-model="booking.dob"
						placeholder="Date of Birth"
						name="dob"
						class="form-flatpickr"
						:config="{
							altFormat: 'M j, Y',
							altInput: true,
							dateFormat: 'd/m/Y',
							static: true
						}">
					</flat-pickr>
				</div>
				<errors for="dob" />
			</div>
		</div>
	</div>
</template>

<script>
	import {AsYouType} from 'libphonenumber-js'
	import flatPickr from 'vue-flatpickr-component'
	import 'flatpickr/dist/flatpickr.css'
	import bookingMixin from '../mixins/bookingMixin'

	export default {
		name: 'BookingRecipientDetailsSection',
		components: {
			flatPickr,
		},
		mixins: [bookingMixin],
		methods: {
			formatPhone() {
				this.booking.phone = new AsYouType(this.$country.toUpperCase()).input(this.booking.phone)
			}
		}
	}
</script>