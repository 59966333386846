<template>
	<div class="h-auto p-8 pb-2 md:pb-4 pt-0 bg-white lg:w-96 border lg:rounded-lg lg:border-black lg:pt-4 shadow-2xl lg:shadow-none text-sm md:text-base">
		<div
			v-if="isDomestic && (currentStep !== lastStep && currentStep !== 0)"
			class="grid grid-cols-2 items-center gap-x-2 lg:hidden py-3 border-b-2 border-primary -mx-8 px-4 border-t-2 border-t-gray-200">
			<button
				v-if="currentStep > 0"
				id="go-back"
				class="back-button"
				@click="goToPreviousStep">
				Go Back
			</button>
			<button
				v-if="currentStep < lastStep"
				id="go-to-next-step"
				class="forward-button"
				@click="goToNextStep">
				Continue
			</button>
		</div>
		<div class="mt-2 md:mt-4">
			<div class="hidden lg:block">
				<h3 class="pt-2 font-bold uppercase lg:text-center">
					Your booking summary
				</h3>
				<div class="flex flex-col pt-4 gap-y-2">
					<div class="flex justify-center">
						<div>Hours:</div>
						<div
							v-if="booking.hours"
							class="font-semibold">
							&nbsp;{{ booking.hours }}
						</div>
						<template v-else>
							&nbsp;-
						</template>
					</div>
					<div class="flex justify-center">
						<div>Clean Type:</div>
						<div
							v-if="booking.service"
							class="font-semibold">
							&nbsp;{{ getServiceDisplayName(booking.service) }}
						</div>
						<template v-else>
							&nbsp;-
						</template>
					</div>
					<div class="flex justify-center">
						<div>Where:</div>
						<div
							v-if="selectedSuburb.id"
							class="font-semibold text-primary">
							&nbsp;{{ selectedSuburb.name }}, {{ selectedSuburb.state }} {{ selectedSuburb.postcode }}
						</div>
						<template v-else>
							&nbsp;-
						</template>
					</div>
				</div>
				<div
					v-if="isCommercial"
					class="grid grid-cols-2 pt-2 gap-y-2">
					<span>Recipient</span>
					<span class="text-right">N/A</span>
					<span>Booker</span>
					<span class="text-right">N/A</span>
					<span>Invoice sent to</span>
					<span class="text-right">N/A</span>
					<span>Who is paying</span>
					<span class="text-right">N/A</span>
				</div>
				<div class="py-2 mt-4 border-t border-gray-300"></div>
				<div class="flex items-center justify-center">
					<h2 class="font-semibold">
						Your Booking Total:
					</h2>
				</div>
				<div class="flex items-center justify-center gap-x-2">
					<p class="text-3xl font-medium text-primary">
						${{ getTotal() }}
					</p>
					<span class="text-gray-500">/ per service</span>
				</div>
				<div
					v-if="hasExtraHourFree"
					class="flex justify-center text-primary">
					* Extra Hour Free on your first clean
				</div>
				<div
					v-if="!isCommercial && booking.service === REGULAR"
					class="flex items-center justify-center">
					<p class="text-sm text-primary">
						* Weekend rates will apply
					</p>
				</div>
				<div
					v-if="hasServiceFee"
					class="flex justify-center">
					<small>* service fee of ${{ getProcessingFee() }}</small>
				</div>
				<div
					v-if="isCommercial && booking.is_ndis"
					class="flex items-center justify-center">
					<p class="text-sm text-primary">
						* Assuming at least 6 services are provided
					</p>
				</div>
			</div>
			<div class="lg:hidden flex flex-col gap-y-0 md:gap-y-2">
				<div class="flex justify-center items-center font-medium gap-x-4 flex-wrap">
					<div v-if="booking.hours">
						{{ booking.hours }} hours
					</div>
					<div v-else>
						-
					</div>
					<span class="text-sm">/</span>
					<div v-if="booking.frequency">
						{{ booking.frequency }}
					</div>
					<div v-else>
						-
					</div>
					<span class="text-sm">/</span>
					<div
						v-if="selectedSuburb.id"
						class="text-primary">
						{{ selectedSuburb.name }}, {{ selectedSuburb.state }} {{ selectedSuburb.postcode }}
					</div>
					<div v-else>
						-
					</div>
				</div>
				<div class="flex justify-center">
					<h1 class="flex items-center gap-x-4">
						Your Booking Total: <span class="text-primary">${{ getTotal() }}</span>
					</h1>
				</div>
				<div
					v-if="hasExtraHourFree"
					class="flex justify-center text-primary">
					* Extra Hour Free on your first clean
				</div>
				<div
					v-if="!isCommercial && booking.service === REGULAR"
					class="flex items-center justify-center">
					<p class="text-sm text-primary">
						* Weekend rates will apply
					</p>
				</div>
				<div
					v-if="hasServiceFee"
					class="flex justify-center">
					<small>* service fee of ${{ getProcessingFee() }}</small>
				</div>
				<div
					v-if="isCommercial && booking.is_ndis"
					class="flex items-center justify-center mt-2">
					<p class="text-sm text-primary">
						* Assuming at least 6 services are provided
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import dayjs from 'dayjs';
	import bookingMixin from '../mixins/bookingMixin';

	export default {
		mixins: [bookingMixin],
		computed: {
			numberOfServices() {
				if (this.booking.num_services) {
					return this.booking.num_services;
				}

				if (this.booking.start_date && this.booking.end_date) {
					let weeks = dayjs(this.booking.end_date, 'D-MM-YYYY').diff(dayjs(this.booking.start_date, 'D-MM-YYYY'), 'week');

					return weeks * this.booking.days;
				}

				return 6;
			}
		},
		methods: {
			getServiceDisplayName(service) {
				switch (service) {
					case this.REGULAR: {
						const frequency = this.booking?.frequency?.toLowerCase().replace(/\b[a-z]/g, function (letter) {
							return letter.toUpperCase();
						})

						return `${this.booking.service} ${frequency ?? ''}`;
					} case this.CATCHUP:
						return 'Occasional Catch Up'
					case this.ONEOFF:
						return 'Spring Clean'
				}
			}
		}
	}
</script>
