import {useBrowserLocation, useStorage} from '@vueuse/core'
import {watch} from "vue";

const browserLocation = useBrowserLocation()
const storage = useStorage('utm', null)

export const useUtm = () => {
  const res = storage.value
  if(typeof res === 'string' || res instanceof String) {
    try {
      return JSON.parse(res)
    } catch(e) {
      return {}
    }
  }

  return {}
}

export const useWatchUtm = () => {
  function storeUtm() {
    const searchParams = new URLSearchParams(browserLocation.value.search);
    if (searchParams.has('utm_source') || searchParams.has('utm_campaign')) {
      let obj = {}

      searchParams.forEach((value, key) => {
        obj[key] = value
      })

      storage.value = JSON.stringify(obj)
    }
  }

  watch(browserLocation, storeUtm)
  storeUtm()
}
