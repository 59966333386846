<template>
	<div>
		<div class="section">
			<h2 v-if="isDomestic">
				When would you like the clean to start?
			</h2>
			<h2 v-if="isCommercial">
				What date should the clean start?
			</h2>
			<div
				id="bookingStartDateContainer"
				class="flex-1">
				<flat-pickr
					id="booking-form-start-date"
					v-model="booking.start_date"
					placeholder="Select a date"
					class="form-flatpickr"
					name="start_date"
					:config="{
						altFormat: 'M j, Y',
						altInput: true,
						minDate: minDate,
						disable: [disabledDates],
						static: true
					}">
				</flat-pickr>
			</div>
			<errors for="start_date" />
		</div>
		<modal ref="dateUnavailableModal">
			<template #body>
				<div class="text-center">
					<h3 class="text-xl">
						{{ dateUnavailableReason }}
					</h3>
				</div>
			</template>
		</modal>
	</div>
</template>

<script>
	import Modal from './Modal.vue'
	import flatPickr from 'vue-flatpickr-component'
	import 'flatpickr/dist/flatpickr.css'
	import dayjs from 'dayjs';
	import bookingMixin from '../mixins/bookingMixin'

	var weekday = require('dayjs/plugin/weekday')
	dayjs.extend(weekday)

	export default {
		components: {
			Modal,
			flatPickr
		},
		mixins: [bookingMixin],
		data() {
			return {
				dateUnavailableReason: 'Sorry, this date is unavailable'
			}
		},
		computed: {
			minDate() {
				if ((new Date()).getHours() >= 14) {
					return (new Date).addDays(2)
				}

				return (new Date()).addDays(1);

			},
			days() {
				let days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

				if (this.booking.type === 'Domestic') {
					days.push('Saturday');
				}

				return days;
			}
		},
		methods: {
			dayjs,
			disabledDates(data) {
				const date = dayjs(data)
				const now = dayjs();
				const currentDay = now.day();
				const currentTime = now.hour();

				// Prevent all bookings on Sundays
				if (date.day() === 0) {
					return true;
				}

				// Check if it's Friday after 2pm or the weekend
				const isFridayOrWeekend = currentDay === 5 && currentTime >= 14 || [0, 6].includes(currentDay);

				// If it's Friday after 2pm or the weekend, prevent bookings for the coming Monday
				if (isFridayOrWeekend) {
					const nextMonday = dayjs().add(1, 'week').day(1).startOf('day');
					return date.isSame(nextMonday, 'day');
				}

				if (currentTime >= 14 && date.isSame(now, 'day')) {
					return true;
				}

				// Commercial bookings cannot be provided on a Saturday
				if (this.isCommercial) {
					return date.day() === 6;
				}

				return false;
			}
		}
	}
</script>
