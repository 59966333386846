<template>
	<div v-if="isCommercial" class="section">
		<h2>Service Paid By*</h2>
		<p class="mt-0">
			Who will be paying for the service?
		</p>
		<div class="grid md:grid-cols-2 gap-4">
			<div>
				<input
					id="company_name"
					v-model="booking.company_name"
					class="form-input"
					placeholder="Company name"
					name="company_name">
				<errors for="company_name" />
			</div>
			<div>
				<input
					id="case_manager_email"
					v-model="booking.company_email"
					class="form-input"
					placeholder="Accounts email address"
					type="email"
					name="company_email">
				<errors for="company_email" />
			</div>
			<div>
				<input
					id="case_manager_name"
					v-model="booking.case_manager_name"
					class="form-input"
					placeholder="Contact name"
					type="text"
					name="case_manager_name">
				<errors for="case_manager_name" />
			</div>
			<div>
				<input
					id="case_manager_phone"
					v-model="booking.case_manager_phone"
					class="form-input"
					placeholder="Contact phone"
					type="email"
					name="case_manager_phone">
				<errors for="case_manager_phone" />
			</div>
			<div>
				<input
					id="company_address"
					v-model="booking.company_address"
					class="form-input"
					placeholder="Address"
					name="company_address">
				<errors for="company_address" />
			</div>
			<div v-if="!booking.is_ndis">
				<input
					id="claim_number"
					v-model="booking.claim_number"
					class="form-input"
					placeholder="Claim number"
					name="claim_number">
				<errors for="claim_number" />
			</div>
			<div v-if="booking.is_ndis">
				<input
					id="company_abn"
					v-model="booking.company_abn"
					class="form-input"
					placeholder="ABN"
					name="company_abn">
				<errors for="company_abn" />
			</div>
		</div>
	</div>
</template>

<script>
	import bookingMixin from '../mixins/bookingMixin'

	export default {
		mixins: [bookingMixin]
	}
</script>