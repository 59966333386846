<template>
	<div class="section">
		<h2>Who is managing the service?</h2>
		<template v-if="booking.custom_service_approver">
			<RadioGroup v-model="booking.approver_type">
				<RadioGroupLabel class="sr-only">
					What is your relationship to the client?
				</RadioGroupLabel>
				<div class="grid grid-cols-2 md:grid-cols-4 gap-4">
					<RadioGroupOption
						v-for="relationshipOption in relationshipOptions"
						:id="`approver_type_${relationshipOption}`"
						:key="`approver_type_${relationshipOption}`"
						v-slot="{ active, checked }"
						as="template"
						:value="relationshipOption"
						class="pill">
						<div
							:class="{active, checked}">
							<div class="flex items-center flex-grow">
								<div class="flex-grow text-sm text-center">
									<RadioGroupLabel as="p">
										{{ relationshipOption }}
									</RadioGroupLabel>
								</div>
							</div>
						</div>
					</RadioGroupOption>
				</div>
			</RadioGroup>
		</template>
		<div
			v-if="booking.approver_type"
			class="grid gap-4 md:grid-cols-2">
			<div>
				<input
					id="approver_first_name"
					v-model="booking.approver_first_name"
					placeholder="First Name"
					name="approver_first_name"
					class="form-input">
				<errors for="approver_first_name" />
			</div>
			<div>
				<input
					id="approver_last_name"
					v-model="booking.approver_last_name"
					placeholder="Last Name"
					name="approver_last_name"
					class="form-input">
				<errors for="approver_last_name" />
			</div>
			<div v-if="booking.approver_type !== 'Family Member'">
				<input
					id="approver_company"
					v-model="booking.approver_company"
					placeholder="Company name"
					name="approver_company"
					class="form-input">
				<errors for="approver_company" />
			</div>
			<div>
				<input
					id="approver_email"
					v-model="booking.approver_email"
					placeholder="Email"
					type="email"
					name="approver_email"
					class="form-input">
				<errors for="approver_email" />
			</div>
			<div>
				<input
					id="approver_phone"
					v-model="booking.approver_phone"
					placeholder="Phone"
					type="text"
					name="approver_phone"
					class="form-input">
				<errors for="approver_phone" />
			</div>
		</div>
	</div>
</template>

<script>
	import {
		RadioGroup,
		RadioGroupLabel,
		RadioGroupOption,
	} from '@headlessui/vue'

	export default {
		name: 'BookingManagedBy',
		components: {
			RadioGroup,
			RadioGroupLabel,
			RadioGroupOption,
		},
		data() {
			return {
				relationshipOptions: [
					'Case Manager',
					'Carer',
					'Family Member',
					'Other'
				]
			}
		}
	}
</script>

<style>

</style>
