<template>
	<div>
		<booking-on-behalf-of @change="handleOnBehalfOfSelection"></booking-on-behalf-of>
		<template v-if="booking.custom_service_approver !== null">
			<booking-managed-by v-if="booking.custom_service_approver"></booking-managed-by>
			<template v-if="showRecipientSection">
				<booking-recipient-details-section></booking-recipient-details-section>
				<booking-plan-type-selector v-if="isCommercial && booking.is_ndis"></booking-plan-type-selector>
				<booking-invoice-details v-if="showInvoiceDetails"></booking-invoice-details>
				<booking-additional-questions></booking-additional-questions>
				<booking-file-upload></booking-file-upload>
			</template>
		</template>
	</div>
</template>

<script>
	import BookingManagedBy from './BookingManagedBy.vue'
	import BookingInvoiceDetails from './BookingInvoiceDetails.vue'
	import BookingRecipientDetailsSection from './BookingRecipientDetailsSection.vue'
	import BookingPlanTypeSelector from './BookingPlanTypeSelector';
	import BookingAdditionalQuestions from './BookingAdditionalQuestions.vue'
	import BookingOnBehalfOf from './BookingOnBehalfOf';

	export default {
		name: 'CommercialWizard',
		components: {
			BookingManagedBy,
			BookingInvoiceDetails,
			BookingRecipientDetailsSection,
			BookingPlanTypeSelector,
			BookingAdditionalQuestions,
			BookingOnBehalfOf,
		},
		data() {
			return {
				step: 1,
			}
		},
		computed: {
			showInvoiceDetails() {
				if (this.isCommercial && !this.booking.is_ndis) {
					return true;
				}

				return this.booking.is_ndis && this.booking.plan_type === 'Plan Managed';
			},
			showRecipientSection() {
				if (this.booking.custom_service_approver === false) {
					return true;
				}

				if (this.booking.custom_service_approver === true && this.booking.approver_type) {
					return true;
				}

				return false
			},
		},
		methods: {
			handleOnBehalfOfSelection(onBehalfOf) {
				if (onBehalfOf) {
					this.step = 2;
				} else {
					this.step = 3;
				}
			},
		},
	}
</script>

<style scoped>

</style>
