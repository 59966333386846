<template>
	<div v-if="hasErrors">
		<div
			v-for="(error, index) in getErrors()"
			:key="`${key}-${index}`"
			class="error flex items-stretch gap-x-2 animate-pulse">
			<exclamation-circle-icon class="w-4 h-4 text-red-500 mt-[2px]" />
			<template v-if="error.message">
				{{ error.message }}
			</template>
			<template v-else>
				{{ error }}
			</template>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
	import { ExclamationCircleIcon } from '@heroicons/vue/outline'

	export default {
		components: {ExclamationCircleIcon},
		props: {
			for: {
				type: String,
				required: true
			}
		},
		computed: {
			...mapGetters([
				'errors',
				'apiErrors',
			]),
			key() {
				return `${this.for}-error`
			}
		},
		methods: {
			hasErrors() {
				return this.getErrors().length > 0
			},
			getErrors() {
				if (!this.errors && !this.apiErrors) return [];

				return [
					...this.errors.filter(error => error.path === this.for),
					...this.apiErrors[this.for] ?? [],
				]
			}
		}
	}
</script>
