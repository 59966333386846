<template>
	<div>
		<button
			id="booking-submit-button"
			:disabled="isSubmitting"
			class="w-full bg-primary text-white uppercase flex items-center justify-center"
			@click.prevent="$emit('submit')">
			<svg
				class="inline-flex w-5 h-5 mr-3 -ml-1 text-white animate-spin"
				:class="{
					'invisible': !isSubmitting,
					'visible': isSubmitting
				}"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24">
				<circle
					class="opacity-25"
					cx="12"
					cy="12"
					r="10"
					stroke="currentColor"
					stroke-width="4"></circle>
				<path
					class="opacity-75"
					fill="currentColor"
					d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
			</svg>
			{{ buttonText }}
		</button>
		<div v-if="Object.entries(apiErrors).length > 0">
			<div class="mt-4 bg-white rounded-lg border border-gray-200 p-4">
				<h5 class="font-bold">
					Please fix the errors:
				</h5>
				<hr class="my-2">
				<div
					v-for="key in Object.keys(apiErrors)"
					:key="`${key}-error`"
					class="error font-bold">
					{{ apiErrors[key]?.join(', ') }}
				</div>
			</div>
			<div class="mt-4">
				<h2>Having Trouble booking?</h2>
				<div class="text-center sm:text-left mt-4">
					Call us on <a
						href="tel:{{ phone }}"
						class="text-primary">{{ phone }}</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import bookingMixin from '../mixins/bookingMixin'

	export default {
		name: 'BookingSubmitButton',
		mixins: [bookingMixin],
		computed: {
			buttonText() {
				if (this.submitting) {
					return 'Processing';
				}

				if (this.isDomestic) {
					return 'Book Now, Pay on the Day';
				}

				return 'Book Now';
			}
		},
	}
</script>
