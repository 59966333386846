<template>
	<div class="section">
		<template v-if="booking.service === REGULAR && booking.is_ndis">
			<h2>
				What is the end date of the plan?
			</h2>
			<small>Must be at least 3 months after the start date.</small>
			<div class="flex flex-col sm:flex-row sm:gap-y-0 gap-y-2 gap-x-4">
				<div
					id="bookingEndDateContainer"
					class="flex-1">
					<div class="rounded-md shadow-sm">
						<flat-pickr
							id="booking-end-date"
							v-model="booking.end_date"
							placeholder="Select a date"
							class="form-flatpickr"
							name="date"
							:config="{
								altFormat: 'M j, Y',
								altInput: true,
								minDate: minDate,
								disable: [disabledDates],
								static: false
							}">
						</flat-pickr>
					</div>
				</div>
			</div>
			<errors for="end_date" />
		</template>
	</div>
</template>

<script>
	import flatPickr from 'vue-flatpickr-component'
	import 'flatpickr/dist/flatpickr.css'
	import dayjs from 'dayjs';
	import bookingMixin from '../mixins/bookingMixin';
	var customParseFormat = require('dayjs/plugin/customParseFormat')

	dayjs.extend(customParseFormat)

	export default {
		name: 'BookingEndDate',
		components: {
			flatPickr,
		},
		mixins: [bookingMixin],
		computed: {
			minDate() {
				if (!this.booking.is_ndis && this.booking.start_date) {
					return this.booking.start_date;
				}

				return dayjs(this.booking.start_date).add(3, 'month').format('YYYY-MM-DD');
			},
		},
		methods: {
			disabledDates(date) {
				return date.getDay() === 0;
			},
		}
	}
</script>
