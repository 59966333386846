import {mapGetters, mapMutations} from 'vuex';
import * as yup from 'yup';
import axios from 'axios';
import dayjs from 'dayjs';
import {useUtm} from '../composables/useUtm';
import {isValidPhoneNumber} from 'libphonenumber-js';

export const REGULAR = 'Regular';
export const ONEOFF = 'Oneoff';
export const CATCHUP = 'Catchup';
export const WINDOW_CLEANING = 'Window Cleaning';
export const ONEOFF_TYPE_BOND = 'Bond';
export const ONEOFF_TYPE_MOVE_IN = 'Move In';
export const ONEOFF_TYPE_SPRING = 'Spring';

const DOMESTIC_VALIDATION_SCHEMA = ({country}) => [
  yup.object().shape({
    service: yup.string().required('Please select a service').nullable(),
  }),
  yup.object().shape({
    first_name: yup.string().required('Please enter your first name'),
    last_name: yup.string().required('Please enter your last name'),
    email: yup.string().email('Please enter a valid email address').required('Please enter your email address'),
    phone: yup
			.string()
			.required('Please enter your phone number')
			.nullable()
			.test(
        'isValidPhoneNumber',
        'Please enter a valid phone number',
        (value) => {
					return isValidPhoneNumber(value || '', country.toUpperCase());
				}
			),
    address: yup.string().required('Please enter your address'),
  }),
  yup.object().shape({
    frequency: yup.string().when('service', {
      is: (service) => service === REGULAR,
      then: yup.string().required('Please select a frequency'),
    }),
    oneoff_type: yup.string().when('service', {
      is: (service) => service === ONEOFF,
      then: yup.string().required('Please select a one-off type'),
    }).nullable(),
    hours: yup.string().when('service', {
      is: (service) => service === ONEOFF,
      then: yup.string().required('Please select the number of hours'),
    }),
    days: yup.string().when('service', {
      is: (service) => service === REGULAR,
      then: yup.string().required('Please select the number of days'),
    }),
  }),
  yup.object().shape({
    preferred_days: yup.array().when('service', {
      is: (service) => service === REGULAR,
      then: yup.array().min(1, 'Please select at least one preferred day'),
    }),
    start_date: yup.date().nullable().default(null).required('Please select a start date'),
    num_bedrooms: yup.number().when('service', {
        is: (service) => service === ONEOFF,
        then: yup.number().required('Please select the number of bedrooms'),
      },
    ),
    num_bathrooms: yup.number().when('service', {
        is: (service) => service === ONEOFF,
        then: yup.number().required('Please select the number of bathrooms'),
      },
    ),
    comments: yup.string(),
  }),
  yup.object().shape({
    payment_method: yup.string().nullable().default(null).required('Please select a payment method'),
  })
];

// eslint-disable-next-line no-unused-vars
const COMMERCIAL_VALIDATION_SCHEMA = (booking) => [
  yup.object().shape({
    frequency: yup.string().required('Please select a frequency'),
		service: yup.string().required('Please select a service'),
    hours: yup.number().required('Please select the number of hours'),
    days: yup.number().required('Please select the number of days'),
    start_date: yup.date().nullable().default(null).required('Please select a start date'),
		end_date: yup.date().nullable().default(null).when('is_ndis', {
			is: (is_ndis) => is_ndis === true,
			then: yup.date().nullable().default(null).required('Please select an end date'),
		}),
		commercial_type: yup.string().required('Please select a service type'),
    custom_service_approver: yup.boolean().required('Please select'),
    preferred_days: yup.array().min(1, 'Please select at least one preferred day'),
		ndis_number: yup.string().when('is_ndis', {
			is: (is_ndis) => is_ndis === true,
			then: yup.string().required('Please enter your NDIS number').matches(/^[4]\d{8}$/, 'Please enter a valid NDIS number'),
		}),
		approver_type: yup.string().when('custom_service_approver', {
			is: (custom_service_approver) => custom_service_approver === true,
			then: yup.string().required('Please select an approver type'),
		}),
		approver_first_name: yup.string().when('custom_service_approver', {
			is: (custom_service_approver) => custom_service_approver === true,
			then: yup.string().required('Please enter the approver first name'),
		}),
		approver_last_name: yup.string().when('custom_service_approver', {
			is: (custom_service_approver) => custom_service_approver === true,
			then: yup.string().required('Please enter the approver last name'),
		}),
		approver_company: yup.string().when(['custom_service_approver', 'approver_type'], {
			is: (custom_service_approver, approver_type) => custom_service_approver === true && approver_type !== 'Family Member',
			then: yup.string().required('Please enter the approver company'),
		}),
		approver_email: yup.string().when(['custom_service_approver', 'approver_phone'], {
			is: (custom_service_approver, approver_phone) => custom_service_approver === true && !approver_phone,
			then: yup.string().email('Please enter a valid email address').required('Please enter the approver email address'),
		}),
		approver_phone: yup.string().when(['custom_service_approver', 'approver_email'], {
			is: (custom_service_approver, approver_email) => custom_service_approver === true && !approver_email,
			then: yup.string().required('Please enter the approver phone number'),
		}),
		plan_type: yup.string().when('is_ndis', {
			is: (is_ndis) => is_ndis === true,
			then: yup.string().required('Please select a plan type'),
		}),
		company_name: yup.string().when('plan_type', {
			is: (plan_type) => plan_type === 'Plan Managed',
			then: yup.string().required('Please enter the company name'),
		}),
		company_email: yup.string().when('plan_type', {
			is: (plan_type) => plan_type === 'Plan Managed',
			then: yup.string().email('Please enter a valid email address').required('Please enter the company email address'),
		}),
		company_address: yup.string().when('plan_type', {
			is: (plan_type) => plan_type === 'Plan Managed',
			then: yup.string().required('Please enter the company address'),
		}),
		company_abn: yup.string().when('plan_type', {
			is: (plan_type) => plan_type === 'Plan Managed',
			then: yup.string().required('Please enter the company ABN'),
		}),
		case_manager_name: yup.string().when('plan_type', {
			is: (plan_type) => plan_type === 'Plan Managed',
			then: yup.string().required('Please enter the case manager\'s name'),
		}),
		case_manager_phone: yup.string().when('plan_type', {
			is: (plan_type) => plan_type === 'Plan Managed',
			then: yup.string().required('Please enter the case manager\'s phone number'),
		}),
    first_name: yup.string().required('Please enter your first name'),
    last_name: yup.string().required('Please enter your last name'),
    email: yup.string().email('Please enter a valid email address').required('Please enter your email address'),
    phone: yup.string().required('Please enter your phone number').nullable(),
    address: yup.string().required('Please enter your address'),
    agree_terms: yup.boolean().required('Please agree to the terms and conditions'),
  }, [
		'approver_phone', 'approver_email'
	]),
];

export default {
  data() {
    return {
      REGULAR,
      ONEOFF,
      CATCHUP,
      WINDOW_CLEANING,
      ONEOFF_TYPE_BOND,
      ONEOFF_TYPE_MOVE_IN,
      ONEOFF_TYPE_SPRING,
    }
  },
  computed: {
    ...mapGetters([
      'isCommercial',
      'isDomestic',
      'isOneoffSelected',
      'isRegularSelected',
      'isCatchupSelected',
      'isRegularEnabled',
      'isOneoffEnabled',
      'isCatchupEnabled',
      'isWindowCleaningEnabled',
      'currentStep',
      'lastStep',
      'errors',
      'apiErrors',
      'isSubmitting',
    ]),
    ...mapGetters({
      user: 'auth/user',
      token: 'auth/getToken',
    }),
    booking: {
      get() {
        return this.$store.getters.booking;
      },
      set(payload) {
        this.$store.commit('setBooking', payload);
      },
    },
    is_guest: {
      get() {
        return this.$store.getters['isGuest'];
      },
      set(payload) {
        this.$store.commit('setIsGuest', payload);
      },
    },
    selectedSuburb: {
      get() {
        return this.$store.getters.getSelectedSuburb;
      },
      set(payload) {
        this.$store.commit('setSelectedSuburb', payload);
      },
    },
    canUseCreditCard() {
      return this.selectedSuburb?.credit_cards_enabled && this.booking.service !== this.ONEOFF && this.booking.type === 'Domestic';
    },
    country() {
      return this.$country;
    },
    phone() {
      return this.country === 'nz' ? '0800663987' : '1300 364 646'
    },
    isAU() {
      return this.$country === 'au'
    },
    isNZ() {
      return this.$country === 'nz'
    },
    showSubmitButton() {
      if (this.isDomestic && this.service === this.REGULAR && this.booking.payment_method === 'Credit Card') {
        return false;
      }

      if (!this.isCommercial) {
        return this.selectedSuburb.id && this.booking.service
      }

      return this.selectedSuburb.id && this.booking.service && this.proceed_with_booking && this.booking.custom_service_approver !== null
    },
    showBookingForm() {
      if (this.booking.type === 'Domestic' && this.booking.service) {
        return true;
      }

      if (this.booking.is_ndis === true) {
        return true;
      }

      return this.booking.is_ndis === false && this.booking.is_dva !== null;
    },
    showBackButton() {
      if (this.isCommercial) return false;

      return this.currentStep > 0;
    },
    showForwardButton() {
      if (this.isCommercial) return false;

      if (this.currentStep === 0 && !this.selectedSuburb.id) {
        return false;
      }

      return this.currentStep < this.lastStep;
    },
    usingCreditCard() {
      return this.booking.payment_method === 'Credit Card';
    },
    hasServiceFee() {
      return this.isDomestic && this.isRegularSelected && this.usingCreditCard
    },
    hasExtraHourFree() {
      return this.extraHourFreePromotionEnabled && this.isDomestic && this.isRegularSelected
    },
	  extraHourFreePromotionEnabled() {
		return false;
	  }
  },
  methods: {
    DOMESTIC_VALIDATION_SCHEMA,
    COMMERCIAL_VALIDATION_SCHEMA,
    ...mapMutations([
      'setCurrentStep',
      'setLastStep',
      'setErrors',
      'setApiErrors',
      'setIsSubmitting',
    ]),
    getHourlyRate(service) {
      return this.selectedSuburb.rates[String(service).toLowerCase()][
        'hourly_rate'
        ];
    },
    getAgencyFee(service) {
      return this.selectedSuburb.rates[String(service).toLowerCase()][
        'agency_fee'
        ];
    },
    getMinimumHours(service) {
      return this.selectedSuburb.minimum_hours[String(service).toLowerCase()];
    },
    getBaseRate(service) {
      return (
        this.getMinimumHours(service) * this.getHourlyRate(service) +
        this.getAgencyFee(service)
      ).toFixed(2);
    },
    getTotal() {
      if (!this.selectedSuburb.id || !this.booking.service || !this.booking.type || !this.booking.hours) {
        return 0.00.toFixed(2);
      }

      if (this.isCommercial && this.booking.hours) {
        if (this.isRegularSelected) {
          let rate = this.selectedSuburb.commercial_rates['regular_long_term']['invoice_hourly_rate'];

          if (this.booking.is_ndis) {
            rate = this.selectedSuburb.commercial_rates['ndis']['invoice_hourly_rate'];
          }

          if (this.booking.is_dva) {
            rate = this.selectedSuburb.commercial_rates['dva']['invoice_hourly_rate'];
          }

          return (this.booking.hours * rate).toFixed(2);
        }

        if (this.isOneoffSelected) {
          let rate = this.selectedSuburb.commercial_rates[this.ONEOFF.toLowerCase()]['invoice_hourly_rate'];
          return (this.booking.hours * rate).toFixed(2);
        }
      }

      const rate = this.selectedSuburb.rates[this.booking.service.toLowerCase()]

      return ((rate.hourly_rate * this.booking?.hours) + rate.agency_fee).toFixed(2)
    },
    getProcessingFee() {
      const STRIPE_PERCENTAGE = 1.2
      const STRIPE_FIXED = 0.27

      const fees = ((this.getTotal() * (STRIPE_PERCENTAGE / 100)) + STRIPE_FIXED) * 1.1;

      return (fees * 0.65).toFixed(2)
    },
    parseError(error) {
      this.setApiErrors([]);

      if (!error.response || !error.request) {
        throw error
      }

      if (error.response.status === 422) {
        for (const [key, value] of Object.entries(error.response.data.errors)) {
          this.setApiErrors({
            [key]: value,
          });
        }
      }

      if (error.response.status === 500 || error.response.status === 400) {
        this.$eventBus.emit('bookingFormErrorModal.open');
      }
    },
    async submit() {
      const valid = await this.validate();

      if (!valid) {
        return;
      }

      this.setIsSubmitting(true)

      let preferred_days = this.booking.preferred_days;

      if (preferred_days.includes('Any')) {
        preferred_days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      }

      let data = {
        suburb_id: this.selectedSuburb.id,
        service: this.booking.service,
        type: this.booking.type,
        is_ndis: this.booking.is_ndis,
        is_dva: this.booking.is_dva,
        ndis_number: this.booking.ndis_number,
        dob: this.booking.dob,
        plan_type: this.booking.plan_type,
        oneoff_type: this.booking.oneoff_type,
        frequency: this.booking.frequency,
        hours: this.booking?.hours,
        start_date: this.booking.start_date ? dayjs(this.booking.start_date).format('D/MM/YYYY') : null,
        end_date: this.booking.end_date ? dayjs(this.booking.end_date).format('D/MM/YYYY') : null,
        is_flexible: 1,
        first_name: this.booking.first_name,
        last_name: this.booking.last_name,
        email: this.booking.email,
        phone: this.booking.phone,
        address: this.booking.address,
        suburb: this.selectedSuburb.name,
        state: this.selectedSuburb.state,
        postcode: this.selectedSuburb.postcode,
        country: this.selectedSuburb?.country.toLowerCase(),
        window_cleaning_required: this.booking.window_cleaning_required,
        window_cleaning_type: this.booking.window_cleaning_type,
        num_windows: this.booking.num_windows,
        num_sliding_doors: this.booking.num_sliding_doors,
        num_bedrooms: this.booking.num_bedrooms,
        num_bathrooms: this.booking.num_bathrooms,
        num_services: this.booking.num_services,
        days: this.booking.days,
        preferred_days: preferred_days,
        message: `${this.booking.comments ?? ''}`,
        custom_service_approver: this.booking.custom_service_approver,
        claim_number: this.booking.claim_number,
        approver_type: this.booking.approver_type,
        approver_phone: this.booking.approver_phone,
        approver_email: this.booking.approver_email,
        approver_company: this.booking.approver_company,
        approver_first_name: this.booking.approver_first_name,
        approver_last_name: this.booking.approver_last_name,
        company_name: this.booking.company_name,
        company_email: this.booking.company_email,
        company_address: this.booking.company_address,
        company_abn: this.booking.company_abn,
        case_manager_name: this.booking.case_manager_name,
        case_manager_phone: this.booking.case_manager_phone,
        case_manager_email: this.booking.case_manager_email,
        approval_documentation: this.booking.approval_documentation,
        stripe_token: this.$store.getters['getStripeToken'],
        payment_method: this.booking.payment_method ? this.booking.payment_method : 'Cash',
        utm: useUtm()
      }

      let domain = window.location.origin

      domain = domain.replace('book.', '');
      domain = domain.replace('training.', '');
      domain = domain.replace('book-staging.', '');

      let params = new URLSearchParams();

      params.append('type', this.booking.type)
      params.append('service', this.booking.service);

      if (this.booking.service === this.ONEOFF) {
        params.append('oneoff_type', this.booking.oneoff_type);
      }

		if (this.booking.service === this.REGULAR) {
			params.append('frequency', this.booking.frequency);
		}

		if (this.booking.payment_method) {
			params.append('payment_method', this.booking.payment_method);
		}

      axios.post('/api/booking', data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${this.token}`,
        },
      }).then(() => {
        window.location.href = `${domain}/book-online/thanks?${params.toString()}`
      }).catch((error) => {
        this.setIsSubmitting(false);
        this.parseError(error);
        this.$nextTick(() => this.scrollToErrors());

        if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_SENTRY_DSN) {
          this.$sentry.setContext('booking', this.booking)
          this.$sentry.setContext('errorData', error.response.data)
          this.$sentry.captureException(error);
        }

        if (error.response.status === 422) {
          return
        }

        throw error;
      })
    },
    async validate(step = this.currentStep) {
      if (step === 0) {
        if (this.selectedSuburb) {
          return true;
        }
      }

      const validationSchema = this.isDomestic
        ? this.DOMESTIC_VALIDATION_SCHEMA({country: this.country})[step - 1]
        : this.COMMERCIAL_VALIDATION_SCHEMA(this.booking)[0];

      if (!validationSchema) return false;

      return await validationSchema
        .validate(this.booking, {abortEarly: false})
        .then(() => {
          this.setErrors([]);

          return true;
        })
        .catch((error) => {
            this.setErrors(error.inner)
            this.$nextTick(() => this.scrollToErrors());

            return false;
          }
        );
    },
    scrollToErrors() {
      const firstError = document.querySelector('.error');

      if (firstError) {
        firstError.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    hasError(field) {
      if (!this.errors) return false;

      return this.errors.some((error) => error.path === field);
    },
    getErrors(field) {
      if (!this.errors) return [];

      return this.errors.filter((error) => error.path === field).map((error) => error.message);
    },
    getErrorsForStep(step) {
      if (this.isCommercial) return [];
      if (!this.errors) return [];

      const fields = this.DOMESTIC_VALIDATION_SCHEMA({country: this.country})[step - 1].fields;

      return Object.entries(fields).filter((field) => {
        if (this.apiErrors[field[0]]) {
          return true;
        }
      });
    },
    async goToStep(step) {
      if (step > this.currentStep) {
        const valid = await this.validate(this.currentStep);

        if (!valid) return;
      }

      this.setCurrentStep(step);
      this.scrollToTop();
    },
    async goToNextStep() {
      if (this.currentStep === this.lastStep) {
        return;
      }

      const valid = await this.validate();

      if (!valid) return;

      if (this.currentStep + 1 === this.lastStep) {
        this.setApiErrors([]);
      }
      this.setCurrentStep(this.currentStep + 1);
      this.scrollToTop();
      this.$nextTick(() => this.updateFocus())
    },
    goToPreviousStep() {
      if (this.currentStep === 0) {
        return;
      }

      this.setCurrentStep(this.currentStep - 1);
      this.scrollToTop();
    },
    updateQueryString(parameters) {
      const query = Object.keys(parameters)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(parameters[key])}`)
        .join('&');

      window.history.replaceState(null, null, `?${query}`);
    },
    updateFocus() {
			if (this.isCommercial) {
				return;
			}

      if (this.currentStep === 1) {
        document.querySelector('#service-regular')?.focus();
      }

      if (this.currentStep === 2) {
        document.querySelector('#first_name')?.focus();
      }

      if (this.currentStep === 3) {
        document.querySelector('#booking-frequency-Weekly')?.focus();
      }

      if (this.currentStep === 4) {
        document.querySelector('#preferred-days-monday')?.focus();
      }
    }
  },
};
