<template>
	<div>
		<commercial-booking-type />
		<template v-if="(booking.is_ndis !== null && booking.is_dva !== null)">
			<booking-frequency-section />
			<booking-hours-selector />
			<div v-if="booking.hours">
				<template v-if="proceed_with_booking === null">
					<div
						class="p-8 py-16 text-center border-2 border-gray-200 border-dashed info max-w-7xl sm:px-6 lg:py-16 lg:px-8 space-y-4 mt-8">
						<h2 class="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl text-center">
							<span class="block">Ready to book or just need a quote?</span>
						</h2>
						<div class="flex justify-center mt-2 md:mt-2">
							<div class="inline-flex">
								<a
									href="#"
									class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-indigo-700 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200"
									@click="$refs.emailQuoteModal.open()">
									Email me a Quote
								</a>
							</div>
							<div class="inline-flex ml-3 rounded-md shadow">
								<button
									id="proceed-with-booking-button"
									class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-primary"
									@click="proceed_with_booking = true">
									Book Now
								</button>
							</div>
						</div>
					</div>
				</template>
				<template v-if="proceed_with_booking">
					<booking-date-selector />
					<booking-days-selector />
					<booking-end-date v-if="booking.is_ndis" />
					<commercial-wizard />
				</template>
			</div>
			<div
				v-if="showSubmitButton && isCommercial"
				class="section">
				<div
					class="warning mt-4"
					@click="booking.agree_terms = !booking.agree_terms">
					<div class="relative flex items-start">
						<div class="flex items-center h-5">
							<input
								id="agree_terms"
								v-model="booking.agree_terms"
								aria-describedby="agree_terms-description"
								value="1"
								name="agree_terms"
								type="checkbox"
								class="form-checkbox" />
						</div>
						<div class="ml-3 text-sm">
							I confirm that the relevant insurer/payer has approved payment of this service for the period entered in
							this form. I confirm that payment will be made within 7 days of the invoice.
						</div>
					</div>
				</div>
				<errors for="agree_terms" />
				<booking-submit-button @submit="submit" />
			</div>
		</template>
		<email-quote-modal ref="emailQuoteModal" />
	</div>
</template>

<script>
	import CommercialBookingType from './CommercialBookingType.vue';
	import BookingDateSelector from './BookingDateSelector.vue';
	import BookingDaysSelector from './BookingDaysSelector.vue';
	import BookingFrequencySection from './BookingFrequencySection.vue';
	import BookingHoursSelector from './BookingHoursSelector.vue';
	import BookingEndDate from './BookingEndDate.vue';
	import CommercialWizard from './CommercialWizard.vue';
	import BookingSubmitButton from './BookingSubmitButton.vue';
	import bookingMixin from '../mixins/bookingMixin';
	import EmailQuoteModal from './EmailQuoteModal.vue';

	export default {
		components: {
			CommercialBookingType,
			BookingDateSelector,
			BookingDaysSelector,
			BookingEndDate,
			CommercialWizard,
			BookingFrequencySection,
			BookingSubmitButton,
			BookingHoursSelector,
			EmailQuoteModal,
		},
		mixins: [bookingMixin],
		data() {
			return {
				proceed_with_booking: null,
			}
		}
	}

</script>
