
<template>
	<div class="section">
		<h1>Type of service</h1>
		<RadioGroup v-model="booking.oneoff_type">
			<RadioGroupLabel class="sr-only">
				What type of service are you looking for?
			</RadioGroupLabel>
			<div class="flex flex-wrap gap-4">
				<RadioGroupOption
					v-for="type in types"
					:id="`oneoff-type-${type.key.toLowerCase()}`"
					:key="`oneoff-type-${type.key.toLowerCase()}`"
					v-slot="{ active, checked }"
					as="template"
					:value="type.key"
					class="pill"
					:disabled="type.disabled"
					style="flex-basis: 144px;">
					<div
						:class="{active, checked}">
						<div class="flex-col items-center flex-grow">
							<div
								v-if="type.disabled"
								class="flex-grow text-sm text-center text-red-500">
								Currently Unavailable
							</div>
							<div class="flex items-center flex-grow">
								<div class="flex-grow text-sm text-center">
									<RadioGroupLabel
										:class="{'opacity-30': type.disabled}"
										as="p">
										{{ type.title }}
									</RadioGroupLabel>
								</div>
							</div>
						</div>
					</div>
				</RadioGroupOption>
			</div>
		</RadioGroup>
		<div class="warning">
			No external windows/no carpet cleaning/no pest control
		</div>
		<errors for="oneoff_type" />
	</div>
</template>

<script>
	import {
		RadioGroup,
		RadioGroupLabel,
		RadioGroupOption,
	} from '@headlessui/vue'
	import bookingMixin from '../mixins/bookingMixin'

	export default {
		name: 'BookingOneoffTypeSelector',
		components: {
			RadioGroup,
			RadioGroupLabel,
			RadioGroupOption,
		},
		mixins: [bookingMixin],
		computed: {
			types() {
				return [
					{
						key: this.ONEOFF_TYPE_BOND,
						title: 'Bond/End of Lease',
					},
					{
						key: this.ONEOFF_TYPE_MOVE_IN,
						title: 'Moving In/Out',
					},
					{
						key: this.ONEOFF_TYPE_SPRING,
						title: 'Spring Clean',
					}

				]
			}
		}
	}
</script>

<style scoped>

</style>
