import axios from 'axios';

const auth = {
  namespaced: true,
  state: {
    user: {},
    token: null,
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    },
  },
  actions: {
    fetchUser({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/user', {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json;charset=utf-8',
              Authorization: `Bearer ${this.state.auth.token}`,
            },
          })
          .then((response) => {
            commit('setUser', response.data.data);
            commit('setDetails', response.data.data, { root: true });
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    login({ commit, dispatch }, { username, password }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/oauth/token', {
            client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
            client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET,
            grant_type: 'password',
            username,
            password,
          })
          .then((response) => {
            commit('setToken', response.data.access_token);
            window.sessionStorage.setItem(
              'access_token',
              response.data.access_token
            );
            dispatch('fetchUser');
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    getToken(state) {
      return state.token;
    },
  },
};

export default auth;
