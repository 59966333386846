<template>
	<div id="app">
		<metainfo></metainfo>
		<Disclosure v-slot="{ open }" as="nav"
			class="bg-white shadow-lg sticky top-0 z-10 px-4">
			<div class="flex justify-between h-16 xl:h-auto xl:py-4 xl:px-0">
				<div class="flex items-center -mr-2 xl:hidden">
					<DisclosureButton class="inline-flex items-center justify-center p-2 px-4 bg-primary rounded-md focus:outline-none">
						<span class="sr-only">Open main menu</span>
						<MenuIcon v-if="!open" class="block w-6 h-6"
							aria-hidden="true"
							:style="{stroke: 'white'}" />
						<XIcon v-else class="block w-6 h-6"
							aria-hidden="true"
							:style="{stroke: 'white'}" />
					</DisclosureButton>
				</div>
				<div class="flex flex-1 justify-center xl:justify-between">
					<div class="flex items-center h-full">
						<img
							v-if="isCleanHomes"
							class="w-auto h-8 xl:h-12 max-h-32"
							src="/svgs/clean-homes-logo.png">
						<img
							v-else
							class="w-auto h-16 xl:h-28 max-h-32"
							src="/absolute-domestics-logo.png">
					</div>
					<div class="hidden xl:-my-px xl:flex flex-grow xl:space-x-8 justify-end">
						<div class="flex-col">
							<div class="flex-col content-end mb-4">
								<div class="flex items-end self-end flex-grow ml-auto xl:justify-end">
									<div class="grid grid-cols-1 xl:grid-cols-2 gap-y-2">
										<div>
											<div class="text-2xl font-semibold text-center xl:text-right text-primary">
												{{ phoneNumber }}
											</div>
											<div>
												enquiries <span class="text-primary">MON-FRI 8:30am - 5pm</span> {{ $country === 'nz' ? 'NZST' : 'AEST' }}
											</div>
										</div>
										<div>
											<a href="/">
												<button class="float-right h-12 font-semibold uppercase bg-primary text-white">
													Pricing & Book Online
												</button>
											</a>
										</div>
									</div>
								</div>
							</div>
							<div class="hidden xl:-my-px flex-nowrap xl:flex-wrap gap-y-4 xl:ml-6 xl:flex gap-x-6">
								<a v-for="item in navigation" :key="item.label"
									:href="item.link"
									class="nav-link"
									:class="{ 'active': item.current }"
									:aria-current="item.current ? 'page' : undefined">
									{{ item.label }}
								</a>
							</div>
						</div>
					</div>
				</div>
				<div class="flex items-center -mr-2 xl:hidden">
					<a :href="`tel:${$country === 'nz' ? '0800663987' : '1300 364 646'}`"
						class="w-10 h-10 fill-current text-primary" file="false">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 70 70">
							<g id="1926640865">
								<path id="1277571417"
									d="M25.3,44.8C35.7,55.3,44.2,56,46.6,56c3.9,0,9.4-5,9.4-6.3v-2.5c0-0.6-0.4-1.1-1-1.3l-10-3.2c-0.5-0.2-1,0-1.4,0.3L40,46.8
		                c-0.4,0.4-1.1,0.5-1.6,0.2c-1.5-0.9-4.8-2.9-8.6-6.8c-3.8-3.8-5.9-7.1-6.8-8.6c-0.3-0.5-0.2-1.2,0.2-1.6l3.7-3.7
                    c0.4-0.4,0.5-0.9,0.3-1.4l-3.2-10c-0.2-0.6-0.7-1-1.3-1h-2.5C19,14.1,14,19.7,14,23.5C14,25.8,14.8,34.3,25.3,44.8L25.3,44.8z">
								</path>
							</g>
						</svg>
					</a>
				</div>
			</div>
			<DisclosurePanel class="xl:hidden">
				<div class="flex flex-col pt-2 pb-3 gap-y-4">
					<a v-for="item in navigation" :key="item.label"
						:href="item.link"
						class="items-center self-center nav-link"
						:class="{ 'active': item.current }"
						:aria-current="item.current ? 'page' : undefined">
						{{ item.label }}
					</a>
				</div>
			</DisclosurePanel>
		</Disclosure>
		<transition name="fade" mode="out-in">
			<div class="lg:container px-0 sm:px-4 lg:mx-auto mb-8"
				style="max-width: 1340px;">
				<booking-form></booking-form>
			</div>
		</transition>
	</div>
</template>

<script>
	import BookingForm from './components/BookingForm.vue'
	import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
	import { MenuIcon, XIcon } from '@heroicons/vue/outline'
	import {useWatchUtm} from "./composables/useUtm";

	let domain = window.location.origin

	domain = domain.replace('book.', '');

	const navigation = [
		{
			label: 'Home',
			link: `${domain}`
		},
		{
			label: 'Services',
			link: `${domain}/services`
		},
		{
			label: 'Pricing & Book Online',
			link: '/',
			current: true,
		},
		{
			label: 'How Does it Work?',
			link: `${domain}/how-does-it-work`
		},
		{
			label: 'About Us',
			link: `${domain}/why-absolute-domestics`
		},
		{
			label: 'Reviews',
			link: `${domain}/reviews`
		},
		{
			label: 'FAQs',
			link: `${domain}/faqs`
		},
		{
			label: 'Become a Cleaner',
			link: `${domain}/become-a-cleaner`
		},
		{
			label: 'Contact Us',
			link: `${domain}/contact-us`
		},
	];
	export default {
		name: 'App',
		components: {
			BookingForm,
			Disclosure,
			DisclosureButton,
			DisclosurePanel,
			MenuIcon,
			XIcon
		},
		metaInfo() {
			const title = this.isCleanHomes ? 'Clean Homes' : 'Absolute Domestics'
			const banner_url = this.isCleanHomes
				? `${window.location.origin}/clean-homes-banner.png`
				: 'https://lirp.cdn-website.com/f477790b/dms3rep/multi/opt/social-default-image-1920w.png'

			return {
				title: title,
				meta: [
					{
						name: 'twitter:title',
						content: `Professional House Cleaning in Australia | ${title}`
					},
					{
						name: 'twitter:image',
						content: banner_url
					},
					{
						property: 'og:title',
						content: `Professional House Cleaning in Australia | ${title}`
					},
					{
						property: 'og:image',
						content: banner_url
					},
				],
				htmlAttrs: {
					lang: 'en',
					amp: true
				},
				link: [
					{
						rel: 'icon',
						href: this.isCleanHomes ? 'favicon-clean-homes.ico' : 'favicon.ico'
					}
				]
			}
		},
		data() {
			return {
				open: false,
				navigation
			}
		},
		computed: {
			domain() {
				return domain
			},
			isCleanHomes() {
				return process.env.VUE_APP_CLEAN_HOMES === 'true';
			},
			phoneNumber() {
				if (this.isCleanHomes) {
					return '1300 305 182';
				}

				if (this.$country === 'nz') {
					return '0800 663 987';
				}

				return '1300 364 646';
			}
		},
		beforeCreate() {
			if (process.env.VUE_APP_CLEAN_HOMES === 'true') {
				document.querySelector('html').classList.add('dark')
			}

			useWatchUtm()
		}
	}
</script>
