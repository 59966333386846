<template>
	<svg xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg"
		class="icon icon-bath" width="16"
		height="16">
		<defs>
			<symbol id="icon-bath" viewBox="0 0 32 32">
				<path d="M26.592 21.291c-0.002 2.306-1.87 4.175-4.176 4.177h-13.291c-2.305-0.003-4.173-1.871-4.175-4.176v-3.446h21.642v3.445zM30.209 15.179h-9.822v-2.453h1.806c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333v0h-6.278c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333v0h1.807v2.453h-7.040v-9.405c0-1.125 0.915-2.039 2.039-2.039s2.039 0.914 2.039 2.039v0.7c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v0-0.7c-0.002-2.599-2.108-4.705-4.707-4.707h-0c-2.598 0.003-4.703 2.108-4.706 4.706v9.407h-6.679c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333v0h0.949v3.445c0 3.055 2.014 5.647 4.782 6.524v2.415c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-2.096h12.078v2.096c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v0-2.415c2.768-0.877 4.781-3.469 4.781-6.524v-3.445h0.951c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333v0z"></path>
			</symbol>
		</defs>
		<use xlink:href="#icon-bath"></use>
	</svg>
</template>
