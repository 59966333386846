<template>
	<div class="section">
		<h2>Will this booking be managed by someone who is <span class="text-primary dark:underline">not</span> the recipient of the cleaning?</h2>
		<p class="text-sm text-center sm:text-left">
			For example, a Case Manager, Carer or Family Member.
		</p>
		<RadioGroup v-model="booking.custom_service_approver">
			<RadioGroupLabel class="sr-only">
				Will this booking be managed by someone who is <b>not</b> the recipient of the cleaning?
			</RadioGroupLabel>
			<div class="flex flex-wrap gap-4">
				<RadioGroupOption
					id="custom-service-approver-yes"
					v-slot="{ active, checked }"
					as="template"
					:value="true"
					class="flex-1 pill">
					<div
						:class="{active, checked}">
						<div class="flex items-center flex-grow">
							<div class="flex-grow text-sm text-center">
								<RadioGroupLabel as="p">
									Yes
								</RadioGroupLabel>
							</div>
						</div>
					</div>
				</RadioGroupOption>
				<RadioGroupOption
					id="custom-service-approver-no"
					v-slot="{ active, checked }"
					as="template"
					:value="false"
					class="flex-1 pill">
					<div
						:class="{active, checked}">
						<div class="flex items-center flex-grow">
							<div class="flex-grow text-sm text-center">
								<RadioGroupLabel as="p">
									No
								</RadioGroupLabel>
							</div>
						</div>
					</div>
				</RadioGroupOption>
			</div>
		</RadioGroup>
		<errors for="custom_service_approver" />
	</div>
</template>

<script>
	import {
		RadioGroup,
		RadioGroupLabel,
		RadioGroupOption,
	} from '@headlessui/vue'

	export default {
		name: 'BookingOnBehalfOf',
		components: {
			RadioGroup,
			RadioGroupLabel,
			RadioGroupOption,
		},
	}
</script>
