<template>
	<modal ref="loginModal">
		<template #body>
			<div class="flex flex-col gap-4 w-64 mx-auto">
				<h3 class="text-2xl font-semibold text-center">
					Login
				</h3>
				<form class="flex flex-col gap-y-4" @submit.prevent="submit">
					<input v-model="username" placeholder="E-Mail Address">
					<input v-model="password" type="password"
						placeholder="Password">
					<button class="w-64 self-center" type="submit">
						<svg v-show="submitting" class="inline-flex w-5 h-5 mr-3 -ml-1 text-white animate-spin"
							xmlns="http://www.w3.org/2000/svg" fill="none"
							viewBox="0 0 24 24">
							<circle class="opacity-25" cx="12"
								cy="12" r="10"
								stroke="currentColor" stroke-width="4"></circle>
							<path class="opacity-75" fill="currentColor"
								d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
						</svg>
						Login
					</button>
				</form>
			</div>
		</template>
	</modal>
</template>

<script>
	import Modal from './Modal'
	import { mapActions } from 'vuex'

	export default {
		components: {
			Modal,
		},
		data() {
			return {
				username: '',
				password: '',
				submitting: false,
			}
		},
		methods: {
			...mapActions({
				login: 'auth/login'
			}),
			open() {
				this.$refs.loginModal.open()
			},
			submit() {
				this.submitting = true;

				this.login({
					username: this.username,
					password: this.password
				}).then(() => {
					this.$refs.loginModal.close()
				}).catch(error => {
					this.parseError(error)
				}).finally(() => {
					this.submitting = false;
				})
			}
		}
	}
</script>


